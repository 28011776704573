<template>
    <div>
            <div class="pb-4">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="alert in (showOnlyUnread ? alerts.filter((a)=> a.read == false) : alerts)" :key="alert._id" class="column is-mobile is-half-tablet is-one-third-desktop">

                        <a class="columns  is-mobile mb-0" >
                            <div @click="routeTo(alert)" class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                                <b-button  :type="alert.read ? 'is-dark' : 'is-primary'" :icon-left="alert.icon"></b-button>
                            </div>
                            <div @click="routeTo(alert)" class="column">
                                <p class="list-title">{{ alert.message }}</p>
                                <p class="has-text-grey small my-1"> {{ alert.date.substr(3) }} </p>
                                <div>
                                    <b-tag v-if="!showOnlyUnread" :class="alert.read ? '' : 'is-primary'">{{ alert.read ? "Read" : "Not read" }}</b-tag>
                                </div>
                            </div>
                            <div @click="markAs(alert)" class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                                
                                <feather v-if="!alert.read" size="20" class="has-text-primary" type="check-square"></feather>
                                <feather v-else size="20" type="check-square"></feather>
                            </div>
                        </a>

                        <hr class="m-0 p-0 mb-0">
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters } from "vuex"

    export default {
        name: 'AlertTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            alerts: {
                required: true
            },
            showOnlyUnread: {
                type: Boolean,
                default: true
            },
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            routeTo(data) {

                
                //
                if (data.link) {
                    //
                    this.$router.push(data.link)
                    // this.$router.push({ name: data.link, 
                    //                 params: {
                    //                     id: data.id,
                    //                     message: data.message,
                    //                     type: data.type, 
                    //                     icon: data.icon,
                    //                     date: data.date,
                    //                     link: data.link,
                    //                 } 
                    //             })
                }

            },
            markAs(alert) {
                // console.log(this.$store)
                //
                // alert
                // alert.read = alert.read == false ? true : false
                axios.patch('/alert/read', { id: alert._id, read: alert.read == false ? true : false }).then(response=>{
                    //
                    if (response) { 
                        this.$buefy.toast.open(`Marked as ${alert.read == false ? "read!" : "unread!"}`);

                        //
                        this.$store.dispatch("alert/getAlert");
                    }
                }).catch(error=>{
                    this.$buefy.toast.open(error.response.data);
                })
                
                
            },
        } 
    }
</script>

<style lang="scss" scoped>// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .list-title {
        font-size: 0.95rem;
    }

    .columnxs {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>