<template>
    <div>
        <div class="p-5">
            <!-- <h4 class="title is-4 m-3 pb-0 mb-0"> Qrcode Scanner</h4> -->

            <div class="" :class="{ fullscreen: fullscreen }" @fullscreenchange="onFullscreenChange" ref="FsWrapper">
                <p> {{ result }} </p>
                <qrcode-stream @decode="onDecode" @init="onInit">
                    <!-- <b-button @click="fullscreen = !fullscreen" class="fullscreen-button p-1"> -->
                    <b-button @click="$router.push('/')" class="fullscreen-button p-1">
                        <!-- <img :src="fullscreenIcon" alt="toggle fullscreen" /> -->
                        <!-- <feather :type="fullscreenIco" size="24" class="is-flex is-justify-content-center p-1"></feather> -->
                        <feather type="home" size="24" class="is-flex is-justify-content-center p-1"></feather>
                    </b-button>
                </qrcode-stream>
                <!-- <qrcode-drop-zone></qrcode-drop-zone> -->
                <!-- <qrcode-capture></qrcode-capture> -->
            </div>
        </div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <!-- <MainFooter /> -->
    </div>
</template>

<script>
    import MainFooter from '../components/footer.vue'

    export default {
        name: 'ScanPage',
        data(){
            return {
                isFullPage: true,
                isLoading: true,
                result: '',
                fullscreen: true
            }
        },
        components: {
            // MainFooter
        },
        computed: {
            // fullscreenIcon() {
            //     if (this.fullscreen) {
            //         return require('@/assets/img/fullscreen-exit.svg')
            //     } else {
            //         return require('@/assets/img/fullscreen.svg')
            //     }
            // },
            fullscreenIco() {
                if (this.fullscreen) {
                    return 'minimize'
                } else {
                    return 'maximize'
                }
            }
        },
        watch: {
          result() {
            if (this.result !== '') {
                const validator = this.result.split('https://app.shopnscan.app/')
                // ratings
                if (validator[1] && validator[1].includes('shop')) {
                    this.$router.push(`index${validator[1]}`)
                }
                // offers
                if (validator[1] && validator[1].includes('offers')) {
                    this.$router.push(`index${validator[1]}`)
                }
                // receipt
                if (validator[1] && validator[1].includes('receipt')) {
                    this.$router.push(`receipt${validator[1]}`)
                }
            }
          } ,
          fullscreen(enterFullscreen) {
            if (enterFullscreen) {
                this.requestFullscreen()
            } else {
                this.exitFullscreen()
            }
          }
        },
        methods: {
            onFullscreenChange(event) {
            // This becomes important when the user doesn't use the button to exit
            // fullscreen but hits ESC on desktop, pushes a physical back button on
            // mobile etc.

                this.fullscreen = document.fullscreenElement !== null
            },
            onDecode(data){
                this.result = data
                console.log(data)
            },
            requestFullscreen() {
                const elem = this.$refs.FsWrapper

                if (elem.requestFullscreen) {
                    elem.requestFullscreen()
                } else if (elem.mozRequestFullScreen) {
                    /* Firefox */
                    elem.mozRequestFullScreen()
                } else if (elem.webkitRequestFullscreen) {
                    /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen()
                } else if (elem.msRequestFullscreen) {
                    /* IE/Edge */
                    elem.msRequestFullscreen()
                }
            },

            exitFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.mozCancelFullScreen) {
                    /* Firefox */
                    document.mozCancelFullScreen()
                } else if (document.webkitExitFullscreen) {
                    /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen()
                } else if (document.msExitFullscreen) {
                    /* IE/Edge */
                    document.msExitFullscreen()
                }
            },
            async onInit(promise) {
                try {
                    await promise
                    this.isLoading = false
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                    error.value = 'ERROR: you need to grant camera access permission'
                    } else if (error.name === 'NotFoundError') {
                    error.value = 'ERROR: no camera on this device'
                    } else if (error.name === 'NotSupportedError') {
                    error.value = 'ERROR: secure context required (HTTPS, localhost)'
                    } else if (error.name === 'NotReadableError') {
                    error.value = 'ERROR: is the camera already in use?'
                    } else if (error.name === 'OverconstrainedError') {
                    error.value = 'ERROR: installed cameras are not suitable'
                    } else if (error.name === 'StreamApiNotSupportedError') {
                    error.value = 'ERROR: Stream API is not supported in this browser'
                    } else if (error.name === 'InsecureContextError') {
                    error.value = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
                    } else {
                    error.value = `ERROR: Camera error (${error.name})`
                    }

                    this.isLoading = false
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .fullscreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    }

    .fullscreen-button {
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem;
    }
    .fullscreen-button img {
    width: 2rem;
    }
</style>