<template>
    <div>
        <div v-for="category in getCategories" :key="category">
            <div v-if="categoryView" class="header mt-3">
                <h3 class="title is-4 mb-3 ml-3 mt-2 has-text-weight-bold"> {{ category }} </h3>
            </div>
            <div class="">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="product in products.filter((pro) => pro.category == category && pro.visible) " :key="product._id" class="column is-half-tablet is-one-third-desktop">
                        <a class="columns is-mobile mb-0 my-0 py-0" :disabled="!product.available" :style="product.available ? '' : 'opacity:0.7'">
                            <span @click="redirectTo(product)" class="column is-one-third ${alreadyInCart(product._id) ? 'inCart' : ''}"> 
                                <b-image v-if="product.image" ratio="4by3" loading="lazy" :src="product.image" :alt="product.image"></b-image>
                            </span>
                            <span :class="`column`"> 
                                <div>
                                    <p @click="redirectTo(product)" class="title is-6 has-text-weight-semibold" > 
                                        <b-tag v-if="alreadyInCart(product._id)" type="is-primary"> {{ inCart(product._id).unit }} 
                                        </b-tag> 
                                        {{ product.product }}
                                    </p>
                                    <p  @click="redirectTo(product)" class="subtitle is-6 mb-2 pt-1"> <b-tag class="mb-1 has-text-primary ">  {{ (product.available ? product.details : 'Out of stock') }}</b-tag> </p>
                                    <span  @click="redirectTo(product)" v-if="product.discount !== 0" class="mr-1">
                                        <span class=" has-text-primary has-text-weight-semibold" > {{ currency }}{{ formatPrice( getDiscount(product.price, product.discount) ) }} </span>
                                        
                                        <span class="mr-1 small has-text-grey" style="text-decoration:line-through"> {{ currency }}{{ formatPrice(product.price) }} </span> 
                                    </span>
                                    <span class=" has-text- has-text-weight-medium" @click="redirectTo(product)" v-else>{{ currency }}{{ formatPrice(product.price) }}</span>
                                    
                                    <div v-if="false" class="columns is-mobile pt-2 pl-3" style="width:25%;">
                                        <div class="column pt-3 pb-1 px-0 mr-3">
                                            <b-button :disabled="!product.available || !alreadyInCart(product._id) && product.qty == 0 " size="is-small" @click="lessQty(product)" icon-left="minus"></b-button>
                                        </div>
                                        <!-- <div class="column py-3 m-1">
                                            <span>{{ getQty(product) }}</span>
                                        </div> -->
                                        <div class="column pt-3 pb-1 px-0">
                                            <b-button :disabled="!product.available" size="is-small" @click="addQty(product)" rounded icon-left="plus"></b-button>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <!-- `${require('@/assets/img/'+product.image+'')}` -->
                            
                        </a>
                        <hr class="m-0 p-0 mt-3">
                    </li>
                </ul>
            </div>
        </div>
         <div v-if="cartCount !== 0" class="order-cover is-flex is-justify-content-center is-align-items-baseline" @click="$router.push('/checkout')" >
                <!-- <div class="pt-2 px-0 cart-cover"> -->
                
                <b-button type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" style="margin-left: -17px" rounded  expanded>
                    <!-- <h6 class=" pt-0 m-0 small" style=""> {{ getCartBusiness }} </h6>  -->
                    <!-- <p class="p-0 m-0"> -->
                        Order {{ '('+cartCount+')' }} - {{ currency }}{{ formatPrice(totalPrice)}}
                    <!-- </p> -->
                    
                </b-button>
            </div>
            
        
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'ProductTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            products: {
                required: true
            },
            currency: {
                type: String,
                required: true
            },
            categoryView: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('cart', ['getCartBusiness']),

            getCategories() {

                const allcategories =  this.products.map(product => product.category)
                const categories  = [...new Set(allcategories)]
                return categories
            },
            
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            loadProduct(product){
                this.currentProduct = product
                //open
                this.isCardModalActive = true
            },
            // getImage(img) {
            //     const fullPath = '../assets/img/'+img
            //     console.log(fullPath)
            //     return fullPath
            // },
            getImage(img) {
                //
                const link = `http://localhost:4000/api/images/product/${this.$store.getters['auth/getUser'].token}/*/${img.split('/')[1]}`
                // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
                return link
            },
            redirectTo (currentProduct) {
                if (!currentProduct.available){
                    return
                }

                this.$router.push({ name: 'product', 
                                    params: { 
                                        id: currentProduct._id,
                                        available: currentProduct.available,
                                        shop: currentProduct.shop, 
                                        image: currentProduct.image,
                                        category: currentProduct.category,
                                        product: currentProduct.product,
                                        tags: currentProduct.tags,
                                        discount: currentProduct.discount,
                                        price: currentProduct.price,
                                        type: currentProduct.type,
                                        details: currentProduct.details,
                                        description: currentProduct.description, 
                                        currency: this.currency
                                    } 
                                })
            },
            alreadyInCart(id) {
                // console.log(this.$store)
                return this.$store.getters['cart/alreadyInCart'](id)
            },
            inCart(id) {
                // console.log(this.$store)
                const cart = this.$store.getters['cart/inCart'](id)
                return cart
            },
            lessQty(product) {
                // if (this.qty <= 1) {
                const unit = this.inCart(product._id).unit

                if (unit !== product.qty) {
                    
                    for (let index = 0; index < unit; index++) {
                        // const element = array[index];
                        product.qty++
                    }
                    
                }
                
                product.qty--

                let formOutput = {
                    available: product.available,
                    id: product._id,
                    business: product.business,
                    discount: product.discount,
                    product: product.product,
                    tags: product.tags,
                    image: product.image,
                    unit: product.qty,
                    price: product.price
                };
                
                this.$store.dispatch('cart/addToCart', formOutput)

                
                // product.totalPrice = product.qty * product.price
                // }
            },
            addQty(product) {

                if (this.getCartBusiness !== null) {

                    if (this.getCartBusiness !== product.business){
                        
                    // })
                        this.$buefy.dialog.confirm({
                            title: 'Starting a new Order?',
                            message: `This will clear your <b>${this.getCartBusiness} </b> Order.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                // empt cart
                                this.$store.dispatch('cart/emptyCart')
                                //
                                product.qty++

                                let formOutput = {
                                    available: product.available,
                                    id: product._id,
                                    business: product.business,
                                    discount: product.discount,
                                    product: product.product,
                                    tags: product.tags,
                                    image: product.image,
                                    unit: product.qty,
                                    price: product.price
                                };
                                
                                this.$store.dispatch('cart/addToCart', formOutput)

                            }
                        })
                    
                        return
                    }
                }

                const unit = this.inCart(product._id).unit

                if (unit !== product.qty) {
                    
                    for (let index = 0; index < unit; index++) {
                        // const element = array[index];
                        product.qty++
                    }
                    
                }
                
                // if (this.inCart(product._id)) {
                //     return
                // }

                product.qty++

                let formOutput = {
                    available: product.available,
                    id: product._id,
                    business: product.business,
                    discount: product.discount,
                    product: product.product,
                    tags: product.tags,
                    image: product.image,
                    unit: product.qty,
                    price: product.price
                };
                
                this.$store.dispatch('cart/addToCart', formOutput)
                
                // product.totalPrice = product.qty * product.price
                
            },
            getQty(product) {
                return product.qty
            }
        } 
    }
</script>

<style lang="scss" scoped>// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .modal-close {
        background: gray !important;
    }
    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        // z-index: 50;
        // width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        // margin-bottom: 5px;
        // bottom: 10px;
    }
</style>