<template>
    <div>

    </div>
</template>

<script>
    export default {
        name: "ReferPage",
        mounted() {
            this.queryChecker()
        },
        methods: {
            queryChecker() {
                const data = this.$route.query
                
                if (Object.keys(data).length !== 0) {

                    if (this.$route.query.link) {

                        localStorage.setItem("refer-link", JSON.stringify(data) )

                        this.$router.push('/')
                    } else {
                        this.$router.push('/')
                    }
                    
                } else {
                    this.$router.push('/')
                }
                
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>