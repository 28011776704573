<template>
    <paystack
        :amount="amount"
        :email="email"
        :paystackkey="paystackkey"
        :reference="reference"
        :callback="callback"
        :close="close"
        :currency="currency"
        :embed="false"
        :channels="channels"
        :metadata="metadata"
        class="button is-primary has-text-weight-semibold is-medium is-rounded is-fullwidth"
    >
       <!-- <i class="fas fa-money-bill-alt"></i> -->
       Pay Now {{ showAmountInButton ? (currencyCode ? currencyCode+(amount/100).toFixed(2) : (amount/100).toFixed(2)) : '' }}
    </paystack>
</template>

<script>
    import paystack from 'vue-paystack';

    export default {
        name: "PayStackTemplate",
        components: {
            paystack
        },
        props: {
            email: {
                type: String,
                default: 'hello@greenshelve.com',
            },
            amount:{
                default: 0
            },
            channels: {
                default: ["card", "bank", "mobile_money"]
            },
            currency: {
                default: 'GHS'
            },
            currencyCode: {
                default: null
            },
            showAmountInButton: {
                default: false
            },
            metadata: {
                default: null
            },
            reference:{
                default: null
            },
            callback:{
                default: null
            },
            close: {
                default: null
            }
        },
        data(){
            return{
                paystackkey: "pk_test_e7fdf5f343fae30653a19e8c36b2760618a9dbd1", //paystack public test key
                paystacklivekey: "pk_live_8fef3ddbc748389231fec80b6595c0b495b07533" //paystack public live key
            }
        },
        computed: {
            _reference(){
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
                for( let i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));
        
                return text;
            }
        },
        methods: {
            _callback: function(response){
                console.log(response)
            },
            _close: function(){
                console.log("Payment closed")
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>