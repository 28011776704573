<template>
    <div>
        <h1 class="title is-4">Wallet</h1>
    </div>
</template>

<script>
    export default {
        name: "WalletPage"
    }
</script>

<style lang="scss" scoped>

</style>