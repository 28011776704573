<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav />
        </div>
        <div class="p-3">
            <div class="card mb-2 ">
                <div class="card-content">
                        <div class="columns is-mobile">
                            <div class="column ">
                                <b-tag icon="check" rounded type="is-primary"> Active</b-tag>
                                <!-- <b-tag icon="close" rounded type="is-danger">Inactive</b-tag> -->
                                <h3 class="title is-4"> 
                                    
                                    Insure me </h3>
                                <p class="subtitle"> Health policy </p>

                                <span>Nov 2022 </span>
                                <!-- <div class="columns is-mobile">
                                    <div class="column">
                                        <h4 class="title is-6">Cover </h4>
                                        <p class="subtitle is-primary"> 200.00</p>
                                    </div>
                                    <div class="column">
                                        <h4 class="title is-6">Premium </h4>
                                        <p class="subtitle is-primary"> 200.00 </p>
                                    </div>
                                    <div class="column" style="text-align:right">
                                        
                                        <b-button rounded icon-left="arrow-right"></b-button>
                                    </div>
                                </div> -->
                            </div>
                            <div class="column" style="margin:auto;text-align:right">
                                <!-- <h1 class="title is-3">$50<span class="subtitle is-5">.00</span> </h1> -->
                                <!-- <span>Nov 2022 </span> -->
                                <b-button rounded>Pay</b-button>
                                <!-- <b-tag icon="close" type="is-danger">Inactive</b-tag> -->
                            </div>
                        </div>
                    <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"> -->
                </div>
                <hr class="my-2">
                <div class="card-content">
                        <div class="columns is-mobile">
                            <div class="column is-two-thirds">
                                <b-tag icon="check" rounded type="is-primary"> Active</b-tag>
                                <!-- <b-tag icon="close" rounded type="is-danger">Inactive</b-tag> -->
                                <h3 class="title is-4"> 
                                    
                                    Funeral Policy</h3>
                                <p class="subtitle"> Health policy </p>
                                <div class="columns is-mobile">
                                    <div class="column">
                                        <h4 class="title is-6">Cover </h4>
                                        <p class="subtitle is-primary"> 200.00</p>
                                    </div>
                                    <div class="column">
                                        <h4 class="title is-6">Premium </h4>
                                        <p class="subtitle is-primary"> 50.00 </p>
                                    </div>
                                </div>
                            </div>
                            <div class="column" style="margin:auto;text-align:right">
                                <!-- <h1 class="title is-3">$50<span class="subtitle is-5">.00</span> </h1> -->
                                <span>1 Year </span>
                                <!-- <b-button rounded>Pay</b-button> -->
                                <!-- <b-tag icon="close" type="is-danger">Inactive</b-tag> -->
                            </div>
                        </div>
                    <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"> -->
                </div>

            </div>
            <div class="card mb-2">
                <div class="card-content">
                        <div class="columns is-mobile">
                            <div class="column is-half">
                                <h3 class="title is-4">Pay med</h3>
                                <p class="subtitle">Medical payment</p>
                                <!-- <span class="is-primary">GHC 120.00</span> -->
                            </div>
                            <div class="column" style="margin:auto;text-align:right">
                                <!-- <h1 class="title is-3">80<span class="subtitle is-5">.00</span> </h1> -->
                                <span class="title is-5"> 120,000<span class="subtitle is-5">.00</span></span>
                                <!-- <b-tag icon="check" type="is-primary"> Active</b-tag> -->
                                <!-- <b-tag icon="close" type="is-danger">Inactive</b-tag> -->
                            </div>
                        </div>
                    <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"> -->
                </div>
                
            <!-- <hr class="my-3"> -->
            </div>
            <div class="card mb-2">
                <div class="card-content">
                        <div class="columns is-mobile">
                            <div class="column is-three-fifths">
                                <h3 class="title is-4"> Med cash</h3>
                                <p class="subtitle">Redeemable amount</p>
                                <!-- <span class="is-primary">GHC 80.00</span> -->
                            </div>
                            <div class="column" style="margin:auto;text-align:right">
                                <span class="title is-5"> 80<span class="subtitle is-5">.00</span></span>
                                <!-- <h1 class="title is-3">₵20<span class="subtitle is-5">.00</span> </h1> -->
                                <!-- <b-tag icon="check" type="is-primary"> Active</b-tag> -->
                                <!-- <b-tag icon="close" type="is-danger">Inactive</b-tag> -->
                            </div>
                        </div>
                    <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"> -->
                </div>

                
            </div>
            <div class="card">
                <div class="card-content">
                        <div class="columns is-mobile">
                            <div class="column is-three-fifths">
                                <h3 class="title is-5"> Total</h3>
                                <p class="subtitle">All contribution</p>
                                <!-- <span class="is-primary">GHC 80.00</span> -->
                            </div>
                            <div class="column" style="margin:auto;text-align:right">
                                <h1 class="title is-5">
                                    <!-- <span class="subtitle is-5">GHC </span><br/> -->
                                    400<span class="subtitle is-5">.00</span> </h1>
                                <!-- <b-tag icon="check" type="is-primary"> Active</b-tag> -->
                                <!-- <b-tag icon="close" type="is-danger">Inactive</b-tag> -->
                            </div>
                        </div>
                    <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"> -->
                </div>
                <div class="p-4">
                    <b-button type="is-primary"  expanded rounded>contribute</b-button>
                </div>
            </div>
            <div class="pt-5 pb-3 px-3">
                <h6 style="float:right"><b> See All </b></h6>
                <h6>Transactions</h6>
            </div>
            <div class="cover-main">
                <div class="card">
                    <div class="card-content">
                        <div class="columns is-mobile content">
                            <div class="column item is-one-fifth text-center" >
                                <feather type="credit-card"></feather>
                            </div>
                            <div class="column">
                                <h6 class="title"> Momo Deposit </h6>
                                <p class="subtitle mb-1">GHC 50.00 </p>
                                <span class="tag is-dark">
                                    Wallet
                                </span>
                            </div>
                            <div class="column is-one-fifth">
                                <p class="small"> Oct 20 </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-content">
                        <div class="columns is-mobile content">
                            <div class="column item is-one-fifth text-center" >
                                <feather type="layers"></feather>
                            </div>
                            <div class="column">
                                <h6 class="title"> Dug Purchase </h6>
                                <p class="subtitle mb-1">GHC 35.00 </p>
                                <span class="tag is-dark">
                                    Med Payment
                                </span>
                            </div>
                            <div class="column is-one-fifth">
                                <p class="small"> Oct 10 </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-content">
                        <div class="columns is-mobile content">
                            <div class="column item is-one-fifth text-center" >
                                <feather type="external-link"></feather>
                            </div>
                            <div class="column">
                                <h6 class="title"> Cashout </h6>
                                <p class="subtitle mb-1">GHC 80.00 </p>
                                <span class="tag is-dark">
                                    Savings
                                </span>
                            </div>
                            <div class="column is-one-fifth">
                                <p class="small"> Oct 8 </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
                <div class="card">
                    <div class="card-content">

                        
                        
                    </div>
                </div>
            </b-modal>
        </div>
        <!-- <div class="columns is-mobile">
                                <div class="column is-half">
                                    <b-field label="" :type="fn.type" :message="fn.message">
                                        <b-input autofocus v-model="form.firstname" placeholder="Firstname"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="ln.type" :message="ln.message">
                                        <b-input v-model="form.lastname" placeholder="Lastname"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="">
                                        <b-input :disabled="(form.country.length !== 0)" v-model="form.country" placeholder="+233" value="233"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="cn.type" :message="cn.message">
                                        <b-input v-model="form.contact" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-half-desktop is-is-two-fifths-mobile">
                                    <b-field label="" :type="gn.type" :message="gn.message">
                                        <b-select
                                            class="mb-4"
                                            expanded
                                            v-model="form.gender"
                                            placeholder=" Gender"
                                            @input="validateGender()"
                                        >
                                            <option value=""> Select</option>
                                            <option value="male"> Male</option>
                                            <option value="female"> Female</option>

                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="dob.type" :message="dob.message">
                                        <b-datepicker
                                            @input="validateDOB()"
                                            placeholder="Date of birth"
                                            v-model="form.dob"
                                        >
                                        </b-datepicker>
                                    </b-field>
                                </div>
                            </div> -->

    </div>
</template>

<script>

import SideNav from '../components/sidenav.vue'
    // import axios from "axios";
    // import feather from 'feather-icons';

    // const icons = Object.keys(feather.icons);
    export default {
        name: "DashboardPage",
        data() {
            return {
                isCardModalActive: false,
                form: {
                    
                },
                // icons,
                tableFields: [
                    // { key: 'naration', label: 'Naration' },
                    // { key: 'time', label: 'Time' },
                    // { key: 'action', label: 'Action' },
                    { key: 'naration', label: '' },
                    { key: 'time', label: '' },
                    { key: 'action', label: '' },
                ],
                items: [
                            { naration: 'Take (2) ', time: '7:00 am', drug: 'Vitamin C tabs 400mg (after meals)' },
                            { naration: 'Take (2) ', time: '2:00 pm', drug: 'Vitamin C tabs 400mg (after meals)' },
                            { naration: 'Take (2) ', time: '9:00 pm', drug: 'Vitamin C tabs 400mg (after meals)' },
                            { naration: 'Take (2) ', time: '8:00 am', drug: 'Ibuprufen tabs 400mg (after meals)' },
                            { naration: 'Take (2) ', time: '8:00 pm', drug: 'Ibuprufen tabs 400mg (after meals)' },
                            { naration: 'Take (2) ', time: '7:00 am', drug: 'Paracetamol tabs 500mg (after meals)' },
                            { naration: 'Take (2) ', time: '2:00 pm', drug: 'Paracetamol tabs 500mg (after meals)' },
                            { naration: 'Take (2) ', time: '9:00 pm', drug: 'Paracetamol tabs 500mg (after meals)' },
                            { naration: 'Take (2) ', time: '8:00 am', drug: 'Coartem tabs 80/480 400mg (after meals)' },
                            { naration: 'Take (2) ', time: '8:00 pm', drug: 'Coartem tabs 80/480 400mg (after meals)' },
                    ],
            }
        },
        components: {
            
        SideNav,
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.$params,
            () => {
                // this.fetchData()
            },
            { immediate: true }

            )

        },
        methods: {
            fetchData() {
                window.axios.get('/patient')
                    .then(response=>{
                    //
                    this.items = response.data
                    })
                    .catch( e => {
                    this.$swal({
                        title:"Failed!",
                        icon: "error",
                        text:e.response.data,
                        type:'error'
                    });
                })
            },
            check(data) {
                this.$swal({
                    title:"Good job!",
                    icon: "success",
                    text: `Successfully recorded `+data.time,
                    type:'success'
                });
            }
        }
    }
</script>

<style lang="scss" >
    .cover {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        margin: auto;
        padding: 10px 30px;
    }

    .hide {
        display: none !important;
    }

    .card, .card-header {
        border: none !important;
    }

    .card, img {
        border-radius: 1rem !important;
    }

    .cover-main .card {
        box-shadow: none;
    }

    .cover-main .card-content {
        padding: 1rem;
        padding-left: 0.7rem;
    }

    .text-center {
        text-align: center;
    }

    .subtitle {
        color: rgba($color: #909090, $alpha: 1.0) !important;
    }

    .small {
        font-size: 0.8rem;
    }

    // div.card:hover,  div.card:focus {
    //     transition: ease-out;
    //     cursor: pointer;
    //     background-color: rgba($color: rgb(230, 230, 230), $alpha: 0.3);
    // }
</style>