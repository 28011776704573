<template>
    <div >
        <div class="page-header">
            <!-- <MainHeader /> -->
        </div>
        <div class="page-body-wrapper" >
            <div class="page-body" >
                <!-- <img v-if="getUser.thereturn" :src="require('@/../public/diaspora1.png')" style="width:100%;position: fixed;bottom:65px;z-index: 0"> -->
                <transition enter-active-class="">
                    <div class="">
                        <img v-if="getUser.thereturn" :src="`${require('@/../public/african.jpg')}`" :style="`
                        opacity: 1;
                        position: absolute;
                        height: 2%;
                        width: 100%;
                        background-color: black;
                        object-fit: cover;
                        border-radius: 0 !important;`" alt="">
                        <!-- <transition name="fadeIn" enter-active-class="animated fadeIn"> -->
                        <router-view class="view"></router-view>
                    </div>
                </transition>
            </div>
            <!-- <MainFooter /> -->
        </div>
    </div>
</template>

<script>
// import MainHeader from './header'

export default {
    name: "MainBody",
    components: { 
        // MainHeader,
    },
    computed: {
        getUser() {
            return this.$store.getters['auth/getUser']
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    // .page-body {
        // padding: 0 17.5px 0 17.5px;
        // padding-top:50px;
        // padding-bottom: 50px;
        // margin-bottom: 80px;
    // }
</style>