
import axios from "axios";
import { store } from '@/store'
import router from '@/router'
import Vue from 'vue'


const initialState = { alerts: [] }

export const alert = {
    namespaced: true,
    state: initialState,
    getters: {
        getAlerts(state) {
            //
            const data = state.alerts
            data.sort((a,b) =>  new Date(b.createdAt) - new Date(a.createdAt))

            return data
        },
        getAlertLink(state) {
            return  state.link
        },
        getAlert: state => (alert) => {
            return state.alerts.find(({visit}) => visit.message.includes(alert))
        },
        searchAlert: state => (alert) => {
            return state.alerts.filter(({visit}) => visit.message.includes(alert))
        },
        searchMainAlert: state => (search) => {
            
            const results = state.alerts.filter(({ message, type, link, date }) => message.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || link.toLowerCase().includes(search.toLowerCase()) || date.toLowerCase().includes(search.toLowerCase()) )

            // const allAlerts =  results.map(product => product.business)
            // const alerts  = [...new Set(allAlerts)]

            return results
        },
        
    },
    actions: {
        getAlert({ commit }, alert) {
            // get latest update in local storage
            // commit('updateAlert')
            let data
            
            //
            axios.get("/alert").then((res)=>{
                //
                if (res.data) {
                    //
                    data = res.data
                        //
                        for (let index = 0; index < data.length; index++) {
                            // const element = array[index];
            
                            
                            // sum ratings
                            const formatted = formatAMPM(data[index].createdAt)
                            // to 2 decimalplaces
                            data[index].date = formatted
            
                            // data.push(data[index])
                            
                        }
                    //
                    commit("updateAlert", data)
                }
            })
        },
        
    },
    mutations: {
        updateAlert:  (state, alert) => {
            //
            // const unreadNew = alert.filter((a)=>a.read == false);
            // const unreadOld = state.alerts.filter((a)=>a.read == false);
            // // compare new alerts to old alerts
            // const compare = alert.filter(({ _id: id1 }) => !state.alerts.some(({ _id: id2 }) => id2 === id1));
            // //
            // if (compare.length > 0) {
            //     //
            //     // push only 10
            //     const al = compare.filter((a)=>a.read == false).splice(0,10)
                    
            //     for (let index = 0; index < al.length; index++) {
            //         const alert = al[index];
                    
            //         Vue.notification.show('Shopnscan', {
            //             body: alert.message
            //         }, {
            //             onclick: function() {
            //                 //
            //                 axios.patch('/alert/read', { id: alert._id, read: alert.read == false ? true : false }).then(response=>{
            //                     //
            //                     if (response) {
                                    
            //                         console.log(`Marked as ${alert.read == false ? "read!" : "unread!"}`)

            //                         if (alert.link) {
            //                             //
            //                             router.push(alert.link)
            //                         }

            //                         //
            //                         store.dispatch("alert/getAlert");
            //                     }
            //                 }).catch(error=>{
            //                     console.log(error.response.data)
            //                 })
            //             },
            //             onshow: function() {
            //                 // this.$router.push('alerts')
            //                 console.log('notification onshow')
            //             }
            //         })
            //     }
            //     //
                
            // } else {
            //     // compare new unread alerts to old unread alerts
            //     const compare = unreadNew.filter(({ _id: id1 }) => !unreadOld.some(({ _id: id2 }) => id2 === id1));

            //     for (let index = 0; index < compare.length; index++) {
            //         const alert = compare[index];
                    
            //         Vue.notification.show('Shopnscan', {
            //             body: alert.message
            //         }, {
            //             onclick: function() {
            //                 //
            //                 axios.patch('/alert/read', { id: alert._id, read: alert.read == false ? true : false }).then(response=>{
            //                     //
            //                     if (response) {
                                    
            //                         console.log(`Marked as ${alert.read == false ? "read!" : "unread!"}`)

            //                         if (alert.link) {
            //                             //
            //                             router.push(alert.link)
            //                         }

            //                         //
            //                         store.dispatch("alert/getAlert");
            //                     }
            //                 }).catch(error=>{
            //                     console.log(error.response.data)
            //                 })
            //             },
            //             onshow: function() {
            //                 // this.$router.push('alerts')
            //                 console.log('notification onshow')
            //             }
            //         })
            //     }
            // }

            state.alerts = alert
            
        },
    }
}


let formatAMPM = (date) => {
    date =  date ? new Date(date) : new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.toDateString()+', '+strTime;
}