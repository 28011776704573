<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <feather @click="goBack" type="arrow-left" size="22" class="p-2 m-5 close"></feather>
        <!-- <feather type="search" size="22" :style="(screenWidth >= 768 ? '':'left: calc(inherit + 968px ) ;right: auto')" class="p-2 m-5 search"></feather> -->
        <!-- <feather type="share-2" size="22" :style="(screenWidth >= 768 ? '':'left: calc(968px ) ;right: auto')" class="p-2 m-5 share"></feather> -->
        <figure  :class="`image  ${(screenWidth <= 560 ? 'is-16by9' : (screenWidth <= 768 ? 'is-3by1' : 'is-4by1') )}`">     
            <img
                :src="coverImage"
            >
        </figure >
        
        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class="p-5">
            <div class="card mb-2 p-2" :style="(screenWidth <= 560 ? 'margin-top:-10.7rem;' : 'width: 65%;margin:auto; margin-top:-10.7rem !important;')" >
                <div class="card-content ">
                        <div class="text-center">
                                <h3 class="title is-4 mb-1 has-text-weight-semibold"> {{ business }} </h3>
                                <p class="small has-text-grey">
                                    <!-- <span class="pr-1"><feather stroke="grey" fill="grey" size="15" type="star"></feather> 4.5</span> -->
                                    {{ type }}
                                </p>
                                <!-- <span class="is-primary">GHC 80.00</span> -->
                            <!-- <div class="column" style="margin:auto;text-align:right">
                                <span class="title is-5"> 80<span class="subtitle is-5">.00</span></span>
                            </div> -->
                        </div>
                        <!-- <hr class="mb-1"> -->
                        <div class="pt-5 px-3 columns is-mobile" hidden>
                            
                            <div class="column text-center pl-0">
                                <feather class="green" size="28" type="thumbs-up"></feather>
                                <p class="small pt-1 has-text-weight-medium"> {{ rate ? rate : 'Not rated' }}</p>
                            </div>
                            <div class="column text-center">
                                <b-icon class="green" size="is-medium" pack="" icon="bike"></b-icon>
                                <p class="small pt-1 has-text-weight-medium" :style="freeDelivery ? 'text-decoration:line-through;' : ''" >  {{ currency }} {{ parseFloat(deliveryFee).toFixed(2) }}</p>
                                <b-tag v-if="freeDelivery" type="is-primary  has-text-weight-semibold" rounded>FREE</b-tag>
                                

                            </div>
                            <div class="column text-center pr-0">
                                <feather class="green has-text-weight-medium" size="28" type="clock"></feather>
                                <p class="small pt-1 has-text-weight-medium">{{ deliveryTime }}</p>
                            </div>
                        </div>
                    <!-- <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image"> -->
                </div>
            </div>

            <div class="pt-2">
                <b-switch class="is-flex is-justify-content-center" size="" v-model="isTab">
                    <span class="small">Tab view</span> 
                    
                </b-switch>
                
                <div class="py-3" style="max-width:460px;margin:auto">
                        <b-field label="">
                            <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" v-model="searchString" placeholder="Search products "></b-input>
                        </b-field>
                </div>
                
                <ProductTemplate :products="result" :currency="currency" v-if="result.length > 0" :categoryView="false" />
                <!-- <b-loading v-model="isLoading" :can-cancel="true"></b-loading> -->
                <ProductTemplate class="p-3" :products="allProducts" :currency="currency" v-if="allProducts.length > 0 && result.length == 0 && !isTab" />
                <b-tabs position="is-centered" v-if="getCategories.length > 0 && isTab && result.length == 0" >
                    <b-tab-item v-for="cat in getCategories" :key="cat" :label="cat">
                        <!--  -->
                        <ProductTemplate :products="allProducts.filter((pro)=>pro.category == cat)" :currency="currency" v-if="allProducts.filter((pro)=>pro.category == cat).length > 0 && result.length == 0" :categoryView="false" />
                    </b-tab-item>
                </b-tabs>
                <!-- <ProductSkeleton v-else/> -->
            </div>
            
            <div :style="cartCount > 0 ? 'padding: 25px' : 'padding: 5px'"></div>
            <!-- {{ allProducts }} -->
            
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions} from 'vuex'
    // import ProductSkeleton from '../components/ProductSkeleton'
    import ProductTemplate from '../components/ProductTemplate'

    export default {
        name: "VendorPage",
        data(){
            return {
                isTab: false,
                currency: '',
                searchString: '',
                isLoading: false,
                screenWidth: 0,
                prevRoute: null,
                business: '',
                type: '',
                freeDelivery: false,
                rate: 0,
                deliveryFee: '',
                coverImage: '',
                deliveryTime: '',
                loading: false,
                result: []
            }
        },
        components: {
            // ProductSkeleton,
            ProductTemplate,
        },
        watch: {
            searchString() {
                if (this.searchString.length == 0) {
                    this.result = []
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            ...mapGetters('products', ['allProducts']),
            ...mapGetters('cart', ['cartCount']),
            getCategories() {

                const allcategories =  this.allProducts.map(product => product.category)
                const categories  = [...new Set(allcategories)]
                return categories
            },
            prevPath() { return this.prevRoute ? this.prevRoute : '/' }
        },
        mounted(){
            // setTimeout(()=>{
                
            // }, 1500)
            console.log(this.$route)

            //
            // this.business = this.$route.query.business
            // this.type = this.$route.query.type
            // this.freeDelivery = this.$route.query.freeDelivery
            // this.rate = this.$route.query.rate
            // this.deliveryFee = this.$route.query.deliveryFee
            // this.coverImage = this.$route.query.coverImage
            // this.deliveryTime = this.$route.query.deliveryTime
            // this.currency = this.$route.query.currency

            //
            
                //
                if (Object.keys(this.$route.params).length !== 0) {
                    
                    const data = {
                        link: 'vendor',
                        data: this.$route.params
                    }

                    this.business = this.$route.params.business
                    this.type = this.$route.params.type
                    this.freeDelivery = this.$route.params.freeDelivery
                    this.rate = this.$route.params.rate
                    this.deliveryFee = this.$route.params.deliveryFee
                    this.coverImage = this.$route.params.coverImage
                    this.deliveryTime = this.$route.params.deliveryTime
                    this.currency = this.$route.params.currency
                    
                    this.$store.dispatch('pages/setPage', data)

                } 
                
                else {
                    
                    const data = this.$store.getters['pages/getPage']('vendor')

                    if (data !== null) {
                        // this.form = data.search
                        this.business = data.business
                        this.type = data.type
                        this.freeDelivery = data.freeDelivery
                        this.rate = data.rate
                        this.deliveryFee = data.deliveryFee
                        this.coverImage = data.coverImage
                        this.deliveryTime = data.deliveryTime
                        this.currency = data.currency
                    }

                    // console.log(data)
                }

            this.updateScreenWidth();
            this.onScreenResize();
            this.getProducts()
        },
        methods: {
            formatPrice(price) {
                return price.toFixed(2)
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            async getProducts() {
                this.isLoading = true
                await this.$store.dispatch('products/getProducts', this.business).then(()=>{
                    this.isLoading = false
                })

                
            },
            getImage(img) {
                //
                const link = `http://localhost:4000/api/images/vendor/${this.$store.getters['auth/getUser'].token}/*/${img}`
                // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
                return link
            },
            goBack() {
                this.$router.back()
            },
            async Search() {
                // console.log('here')
                if (this.searchString.length >= 3) {
                    
                   
                    const result = await this.$store.getters['products/searchProduct'](this.searchString)

                    this.result = result
                    
                }
            },
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
        }
    }
</script>

<style lang="scss" >
// custom
@import '@/assets/scss/custom_variables.scss';

.tabs li.is-active a {
    border-bottom-color: $primary !important;
    color: $primary !important;
}

.medium {
    font-size: 0.8rem !important;
}

.bold {
    font-weight: 500;
}

.close {
    border-radius: 100%;
    position: fixed;
    z-index: 30; 
    color:white;
    background: rgba(0,0,0,0.5);
}

.search {
    border-radius: 100%;
    position: absolute;
    z-index: 100; 
    color:white;
    background: rgba(0,0,0,0.5);
    right: 0;
}

.share {
    border-radius: 100%;
    position: absolute;
    z-index: 100; 
    color:white;
    background: rgba(0,0,0,0.5);
    right:  50px ;
}

.green {
    color: grey;
    // color: $primary
}

.image   img {
    box-shadow: 0 0.5em 1em -0.125em rgba(0, 0, 0, 0.1), 0 0px 0 1px rgba(0, 0, 0, 0.02);
}
</style>