<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class="pb-5">
            <!-- <h4 class="subtitle is-2 mb-0" style="text-align:center">Heath <i class="has-text-danger">Line</i></h4> -->
            <!-- <p style="text-align:center;font-weight:300" class="subtitle is-6 has-text-gray">your health in your palm</p> -->
        </div>
        <div class="auth">
            <div class=" px-3 py-4">
                <div class="-content">
                    <div class="content">
                        <div class="pb-1 text-center mb-5">
                            <img style="width:180px;" :src="require('@/../public/favicon.png')" class="logo "/>
                            <h1 class="title title is-1 mt-0">Shopnscan<span style="font-weight:300;"></span></h1>
                            <p class="subtitle is-5 has-text-grey">Discover discounts & Offers</p>
                        </div>
                        <div class="pt-5 pb-2">
                            <div class="columns is-mobile mb-0">
                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="" message="">
                                        
                                        <!-- <span class="ico left-icon"> -->
                                            <!-- <feather type="user"></feather> -->
                                        <!-- </span> -->
                                        <vue-country-code
                                            class=" is-size-5"
                                            @onSelect="onSelect"
                                            :enabledCountryCode="true"
                                            :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                            >
                                        </vue-country-code>
                                        <!-- <b-input v-model="form.code" disabled placeholder="+233"></b-input> -->
                                        
                                    </b-field>

                                    <div style="display:none" class="control has-icons-left has-icons-right">
                                        
                                        <span class="ico is-left">
                                            
                                            <feather type="user"></feather>
                                        </span>
                                        <!--  -->
                                        <input class="input" :disabled="(form.country.length !== 0)" type="text" v-model="form.country" placeholder="+233" value="233">
                                    </div>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="type" :message="message">
                                        <b-input type="number"  @input.native="validateContact()" @keyup.native.enter="onSubmit" autofocus v-model="form.contact" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            
                            <div class="notification small text-center"> Enter contact to signin / signup.
                                <!-- <router-link  to="/setup">Sign up </router-link> -->
                                <!-- <router-link  to="/verifyotp">Verify </router-link> -->
                                <!-- <router-link  to="/index">index </router-link> -->
                                <!-- <router-link  to="/home">Home </router-link> -->
                                <!-- <vue-feather type="star"></vue-feather> -->
                            </div>
                            <b-field class=" text-center">
                                <b-button @click="onSubmit" rounded expanded class="is-submit-button" type="is-primary">Send OTP</b-button>
                            </b-field>
                            <div class=" text-center m-5 pt-5">
                                <div class="mt-5">
                                    <p  class="is-text-primary">Shopnscan.app</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Axios from 'axios';
    import axios from 'axios'
    import { userService } from '../services';

    export default {
        name:'SigninPage',
         data() {
            return {
                isLoading: false,
                isFullPage: true,
                form: {
                    country: '',
                    contact: '',
                    code: '+233',
                    remember: true,
                    type: '',
                    message: ''
                },
                type: '',
                message: '',
                show: true
            }
        },
        mounted() {
            // check query
            this.queryChecker()
            //
        },
        methods: {
            queryChecker() {
                const data = this.$route.query
                
                if (Object.keys(data).length !== 0) {
                    localStorage.setItem("shop-link", JSON.stringify(data) )
                }
                
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)
                
                if (reg.test(this.form.contact)) {

                    if (this.form.contact.length <=8){
                        this.type = 'is-danger';
                        this.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.contact.length > 8 && this.form.contact.length < 11){
                        this.type = 'is-success';
                        this.message =''
                        return true
                    } else {
                        this.type = 'is-danger';
                        this.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.contact.length <=8){
                        this.type = 'is-danger';
                        this.message ='Kindly enter a contact number'
                        return false
                    }
                }

                
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if (!this.validateContact()){
                    return
                }

                this.isLoading = true

                // 
                userService.login(this.form.code, this.form.contact, this.form.remember ).then(response => {
                    //
                    // console.log(response)
                    this.type = response.type


                    // 
                    setTimeout(() => {
                        //
                        this.$buefy.toast.open(response.message)
                        //
                        setTimeout(() => {
                            this.isLoading = false

                            //
                            this.$router.push({ name: 'verifyotp', params: { code: this.form.code, contact: this.form.contact, type: this.type } })

                            //
                            // this.$buefy.dialog.prompt({
                            //     // title: 'Verify Code',
                            //     message: `Enter the 4-digit OTP code received!`,
                            //     inputAttrs: {
                            //         placeholder: 'X X X X',
                            //         maxlength: 60
                            //     },
                            //     trapFocus: true,
                            //     onConfirm: (value) => {
                            //         //
                            //         this.form.OTP = value

                                    

                            //     }
                            // })
                        }, 800)
                     }, 1.2 * 1000)

                }).catch(error=>{
                    //
                    // console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // ajax
                // axios.post('/user/login', this.form).then(response=>{
                //     //
                //     console.log(response.data)
                //     // 
                //     this.form.type = response.data.type
                //     this.$buefy.toast.open(response.data.message)

                //     //

                // }).catch(error=>{
                //     //
                //     console.log(error)
                //     //
                //     this.$buefy.toast.open(error.response.data)
                // })

                

                

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                console.log(name, iso2, dialCode);
            }
        }
    }
</script>

<style lang="scss" >
.auth {
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 460px;
    margin: auto;
    // margin-top: 3.5rem;
    text-align: left;
}

.vue-country-select  {
    width: 100%
}

.vue-country-select > .dropdown {
    padding: 0.6em !important;
}
</style>