<template>
    <div>
        <div class="background" >
            <!-- <div class="text-center">
                <img style="width:320px;" :src="require('@/assets/img/logo1.png')" class="logo "/>
            </div> -->
            <router-view>
                
            </router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainAuth',
        data() {
            return {
                screenWidth: 0,
            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
        }
    }
</script>

<style lang="scss" scoped>
.background {
    padding: 10px;
    // padding-bottom: 200px;
    // background-image: ;
    // background-size: contain;
    background-position: 100% 100%;
    height: 100vh;
    margin: auto !important;
    background-repeat: no-repeat;
}
</style>