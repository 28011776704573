<template>
    <div>
        <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
        <div class=" p-5"  :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <!-- <feather v-if="cartCount > 0" @click="emptyCart" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather> -->
            <h4 class="title is-4 ml-5 pl-5">My Gifts </h4>
            
            <div class="p-3">
                <!-- <h4 class="title is-4"> Order Summary </h4> -->
                <!-- <p class="is-5 "> {{ getCartBusiness }}</p> -->
            </div>
            
            <!--  -->
            <!-- Total -->
            <div style="height:70vh" class="p-5 m-3 text-center is-flex is-justify-content-center is-align-items-center">
                
                <p class=""> :( No Free Coupons today</p>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"

    export default {
        name: "GiftPage",
        data() {
            return {
                business: '',
                businessData: null,
                isOpen: true,
                isLoading: false,
                screenWidth: 0
            }
        },
        components: {
        },
        computed: {
            
        },
        mounted() {
            // this.business = this.$store.getters['cart/getCartBusiness']
            this.updateScreenWidth();
            this.onScreenResize();
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBack() {
                this.$router.back()
            },
            
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>