<template>
  <div :class="classes">
    <div class="">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="order in orders" :key="order._id" class="column is-half-tablet is-one-third-desktop">
                        <a  class="columns is-mobile my-0 py-0">
                            <span @click="viewDetails(order)" class="column is-3 p-0"> 
                                <div class="px-2 pl-4 ">
                                    <b-image v-if="order.coverImage" :src="order.coverImage" ratio="1by1" loading="lazy"></b-image>
                                    
                                </div>
                            </span>
                            <span @click="viewDetails(order)" :class="`column p-0`"> 
                                <div class="px-2 ">
                                    
                                    <!-- <p class="title mb-1 is-5 has-text-weight-semibold" style="font-weight: 300"> 
                                      Order  {{ order.orderNo.split('-')[0] }}
                                    </p> -->
                                    <p  class=" is-size-6 mb-3 has-text-weight-semibold">{{ order.vendor }} </p>
                                    <p  class=" is-5 mb-1 has-text-weight-medium is-flex is-justify-content-left is-align-items-center">{{ order.data.country.currency+formatPrice(order.total) }} <b-tag class="ml-2" rounded :type="`${order.status == 'completed' ? 'is-primary' : order.status == 'confirmed' ? 'is-link' : 'is-warning'} has-text-weight-semibold`"> {{ order.status }} </b-tag> </p>
                                    <span class="mr-1 has-text-grey small"> {{ formatAMPM(order.createdAt) }} </span>
                                
                                </div>
                            </span>
                            <span class="column is-2 is-flex is-justify-content-left is-align-items-center"> 
                                <div style="margin-left:-12px;">
                                    <b-button @click="checkStatus(order)" :icon-left="order.status =='completed' ? 'repeat' : order.scheduled ? 'repeat' : order.status == 'cancelled' ? 'delete' : 'pin'" rounded type="is-">
                                        <!-- <feather  :type="order.status =='completed' ? 'repeat' : order.scheduled ? 'repeat' : 'navigation'"></feather> -->
                                    </b-button>
                                    
                                    
                                </div>
                            </span>
                        </a>
                        <hr class="m-0 p-0 mt-3">
                    </li>
                </ul>
            </div>
  </div>
</template>

<script>
export default {
  name: 'OrderTemplate',
  data() {
    return {
        screenWidth: 0
    }
  },
  props: {
    orders: {
      required: true
    },
    classes: {
      type: String,
    }
  },
  mounted(){
            this.updateScreenWidth();
            this.onScreenResize();
  },
  methods: {
    formatPrice(price) {
        return price.toFixed(2)
    },
    formatAMPM(date) {
        date =  date ? new Date(date) : new Date()
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return date.toDateString()+', '+strTime;
    },
    onScreenResize() {
        window.addEventListener("resize", () => {
            this.updateScreenWidth();
        });
    },
    updateScreenWidth() {
        this.screenWidth = window.innerWidth;
    },
    viewDetails(order) {
        console.log(order.orderNo)
        this.$router.push({ name: 'orderdetails', 
                          params: { orderNo: order.orderNo }
                        });
    },
    redirectTo (shop) {
      this.$router.push({ name: 'vendor', 
                          params: 
                            { 
                                business: shop.business, 
                                type: shop.type, 
                                deliveryFee: shop.deliveryFee, 
                                deliveryTime: shop.deliveryTime, 
                                freeDelivery: shop.freeDelivery, 
                                rate: shop.rate, 
                                coverImage: shop.coverImage,
                                currency: shop.data.country.currency
                            } 
                        })
    },
    checkStatus(order) {
        //
        if (order.status == "completed") {
            //
            this.$store.dispatch('cart/setCart', order.items)
            //
            this.$buefy.toast.open('')
        }
        else if (order.status == "confirmed") {
            //
        }
    },
    getImage(img) {
      //
      const link = `http://localhost:4000/api/images/vendor/${this.$store.getters['auth/getUser'].token}/*/${img}`
      // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
      return link
    }
  }
}
</script>

<style lang="scss" scoped>

</style>