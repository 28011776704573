import sha256 from "sha256";
import axios from "axios";

const shop = localStorage.getItem('shop-link') ? JSON.parse(localStorage.getItem('shop-link')) :  false;
const location = localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location')) :  false;

const _shop = shop
    ? { shops: [], link: shop }
    : { shops: [], link: null };

const _location = location
    ? location
    : { location: '', address: '', addressList: [] };

const initialState = { ..._shop, ..._location }

export const shops = {
    namespaced: true,
    state: initialState,
    getters: {
        getShops(state) {
            // return  state.shops

            const data = state.shops
            data.sort((a,b) =>  a.name.localeCompare(b.name))

            return data
        },
        getShopLink(state) {
            return  state.link
        },
        getShop: state => (shop) => {
            return state.shops.find(({Shop}) => Shop.name.includes(shop))
        },
        searchShop: state => (shop) => {
            return state.shops.filter(({Shop}) => Shop.name.includes(shop))
        },
        searchMainShop: state => (search) => {
            
            const results = state.shops.filter(({ name, locationName, type, categories }) => name.toLowerCase().includes(search.toLowerCase()) || locationName.toLowerCase().includes(search.toLowerCase()) || type.toLowerCase().includes(search.toLowerCase()) || categories.find((cat)=> cat.toLowerCase().includes(search.toLowerCase())))

            // const allShops =  results.map(product => product.business)
            // const shops  = [...new Set(allShops)]

            return results
        },
        address: (state) =>  {
            return { address: state.address, location: state.location }
          },
        allAddresses: (state) => {
            //
            return state.addressList
          },
          getAddress: (state) => (name) => {

            
            const results = state.addressList.find(({address}) => address.toLowerCase().includes(name.toLowerCase()))
        
            return results
          },
          parseAddress: (addressObject) => {
            //
            const parsed = [addressObject.lat, addressObject.lng]

            return parsed
          },
        verifyShop: (state) => (name, hash) =>  {
            
            //
            const secret = 225466879314

            if (sha256(name+secret) == hash) {
                return true
            } else {
                return false
            }
        },
        generateShopHash: (state) => (name) => {
            const secret = 225466879314
            //
            return sha256(name+secret)
        },
        currentBusiness: state => (name) => {
            // if (business) { return }
        
            const results = state.shops.find(({ name }) => name.includes(name))
        
            return results
        },
        delivery: (state) =>  {
            return { address: state.address, location: state.location }
        },
    },
    actions: {
        async getShop({ commit }, param) {
            // get latest update in local storage
            // commit('updateShop')
            let data
            let link = param.type == "shop" ? "/shop?shop="+param.shop : "/shop?link="+param.shop

            if (param.type == "link") {
                //
                commit('updateLink')
            }
            //
            await axios.get(link).then((res)=>{
                //
                if (res.data) {
                    //
                    data = res.data
                    //
                    for (let index = 0; index < data.length; index++) {
                        // const element = array[index];
                        const rateLen = data[index].ratings.length
        
                        if (rateLen > 0) {
                        // sum ratings
                        const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                        // to 2 decimalplaces
                        data[index].rate = parseFloat(rate.toFixed(1))
        
                        // data.push(data[index])
                        }
                    }
                    //
                    commit("updateShop", data)
                }
            })
        },
        getAllShops({ state, commit }) {

            let data

            if (state.address !== "") {

                const loc = [state.location.lat, state.location.lng]
                
                axios.post("/shop/location", { location: loc } ).then((res)=>{
                    //
                    if (res.data) {
                        //
                        data = res.data
                        //
                        for (let index = 0; index < data.length; index++) {
                            // const element = array[index];
                            const rateLen = data[index].ratings.length
            
                            if (rateLen > 0) {
                            // sum ratings
                            const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                            // to 2 decimalplaces
                            data[index].rate = parseFloat(rate.toFixed(1))
            
                            // data.push(data[index])
                            }
                        }
                        //
                        commit("updateShop", res.data)
                    }
                })

            } else {
                
                // axios.get("/shop/").then((res)=>{
                //     //
                //     if (res.data) {
                //         //
                //         data = res.data
                //         //
                //         for (let index = 0; index < data.length; index++) {
                //             // const element = array[index];
                //             const rateLen = data[index].ratings.length
            
                //             if (rateLen > 0) {
                //             // sum ratings
                //             const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                //             // to 2 decimalplaces
                //             data[index].rate = parseFloat(rate.toFixed(1))
            
                //             // data.push(data[index])
                //             }
                //         }
                //         //
                //         commit("updateShop", res.data)
                //     }
                // })
            }
            
        },
        addPlace({ state, commit }, data) {

          // console.log(data)

          if (typeof(data) === 'object' || data.address || data.location) {
            //
            const found = state.addressList.find((place)=> place.address.includes(data.address))

            if (state.address !== data.address) {
              //
              commit('addPlace', data)
            }
            
          }
        },
        clearLink({ commit }) {
            
            commit('clearLink')
        }
    },
    mutations: {
        updateShop:  (state, shops) => {
            //
            state.shops = shops
        },
        addPlace: (state, data) => {
          // set
          state.address = data.address
          state.location = data.location
          // check before push
          const found = state.addressList.find((place)=> place.address.includes(data.address))

          if (!found) {
            state.addressList.push(data)
          }

          localStorage.setItem('location', JSON.stringify({ location: data.location, address: data.address, addressList: state.addressList }))
          
        },
        updateLink:  (state) => {
            
            //
            const shop = localStorage.getItem('shop-link') ? JSON.parse(localStorage.getItem('shop-link')) : false
            state.link = shop

            //
            localStorage.removeItem('shop-link')
        },
        clearLink: (state) => {
            
            //
            state.link = null
            localStorage.removeItem('shop-link')

        }
    }
}