<template>
    <div>
        <h1 class="title is-4">Notifcation</h1>
    </div>
</template>

<script>
    export default {
        name: "NotifcationPage"
    }
</script>

<style lang="scss" scoped>

</style>