<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class="pb-5">
            <!-- <h4 class="subtitle is-2 mb-0" style="text-align:center">Heath <i class="has-text-danger">Line</i></h4> -->
            <!-- <p style="text-align:center;font-weight:300" class="subtitle is-6 has-text-gray">your health in your palm</p> -->
        </div>
        <div class="auth">
            <div class=" px-3 py-4">
                <div class="-content">
                    <div class="content">
                        <div class="pb-5 text-center">
                            <h1 class="title title is-2">Green<span style="font-weight:300;">Shelve</span></h1>
                            <p class="subtitle is-6 has-text-grey">Enter details below</p>
                        </div>
                        <div class="pt-5 pb-2">
                            <div class="columns is-mobile">
                                <div class="column is-half">
                                    <b-field label="" :type="fn.type" :message="fn.message">
                                        <b-input @input="validateFname()" autofocus v-model="form.firstname" placeholder="Firstname"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="ln.type" :message="ln.message">
                                        <b-input @input="validateLname()" autofocus v-model="form.lastname" placeholder="Lastname"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="">
                                        <b-input :disabled="(form.country.length !== 0)" v-model="form.country" placeholder="+233" value="233"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="cn.type" :message="cn.message">
                                        <b-input @input="validateContact()" v-model="form.contact" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-half-desktop is-is-two-fifths-mobile">
                                    <b-field label="" :type="gn.type" :message="gn.message">
                                        <b-select
                                            class="mb-4"
                                            expanded
                                            v-model="form.gender"
                                            placeholder=" Gender"
                                            @input="validateGender()"
                                        >
                                            <option value=""> Select</option>
                                            <option value="male"> Male</option>
                                            <option value="female"> Female</option>

                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="dob.type" :message="dob.message">
                                        <b-datepicker
                                            @input="validateDOB()"
                                            placeholder="Date of birth"
                                            v-model="form.dob"
                                        >
                                        </b-datepicker>
                                    </b-field>
                                </div>
                            </div>

                            <div class="notification">
                                <button class="delete"></button>
                                Submit details to register and <strong>verify your 
                                 contact</strong>, For more info: <a href="mailto:contact@platfrom.com" class="has-text-primary">contact@platform.com</a>. Sign In here 
                                 <strong><router-link class="has-text-primary" to="/signin">Sign in </router-link></strong> 
                            </div>
                            
                            <b-field :type="ag.type" :message="ag.message">
                                <b-checkbox v-model="form.checkbox">I agree to the <a>Terms & Conditions</a> and <a>privacy policy</a>.</b-checkbox>
                            </b-field>
                            
                            <!-- <span> 
                                <router-link  to="/signin">Signin </router-link>
                            </span> -->
                            <b-field class="pt-5">
                                <b-button @click="onSubmit" class="is-submit-button" type="is-dark">Submit</b-button>
                            </b-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'SignupPage',
         data() {
            return {
                isLoading: false,
                isFullPage: true,
                form: {
                    firstname: '',
                    lastname: '',
                    country: '+233',
                    contact: '',
                    code: '',
                    gender: null,
                    dob:'',
                    checkbox: false,
                },
                fn: {
                    type:'',
                    message:'',
                },
                ln:{
                    type:'',
                    message:'',
                },
                cn:{
                    type:'',
                    message:'',
                },
                gn:{
                    type:'',
                    message:'',
                },
                dob:{
                    type:'',
                    message:'',
                },
                ag:{
                    type:'',
                    message:'',
                },
                show: true
            }
        },
        methods: {
            validateFname() {
                if (this.form.firstname.length < 3){
                    this.fn.type = 'is-danger';
                    this.fn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.firstname.length >= 3){
                    this.fn.type = 'is-success';
                    this.fn.message =''
                    return true
                }
            },
            validateLname() {
                if (this.form.lastname.length < 3){
                    this.ln.type = 'is-danger';
                    this.ln.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.lastname.length >= 3){
                    this.ln.type = 'is-success';
                    this.ln.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)
                
                if (reg.test(this.form.contact)) {

                    if (this.form.contact.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.contact.length > 8 && this.form.contact.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.contact.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }

                
            },
            validateGender() {
                if (this.form.gender == null || this.form.gender == ''){
                    this.gn.type = 'is-danger';
                    this.gn.message ='Kindly select a gender'
                    return false
                }

                if (this.form.gender.length > 0){
                    this.gn.type = 'is-success';
                    this.gn.message =''
                    return true
                }
            },
            validateDOB() {
                if (this.form.dob == ''){
                    this.dob.type = 'is-danger';
                    this.dob.message ='Kindly select a date'
                    return false
                }

                if (this.form.dob){
                    this.dob.type = 'is-success';
                    this.dob.message =''
                    return true
                }
            },
            validateAgree() {
                if (!this.form.checkbox){
                    this.ag.type = 'is-danger';
                    this.ag.message ='Kindly agree to proceed!'
                    return false
                }

                if (this.form.checkbox){
                    this.ag.type = 'is-success';
                    this.ag.message =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateFname() || !this.validateLname() || !this.validateContact() || !this.validateGender() || !this.validateDOB() || !this.validateAgree()){
                    return
                }

                this.isLoading = true

                //

                setTimeout(() => {
                    this.isLoading = false

                    //
                    this.$buefy.dialog.prompt({
                        // title: 'Verify Code',
                        message: `Enter the 4-digit verify code received!`,
                        inputAttrs: {
                            placeholder: 'X X X X',
                            maxlength: 60
                        },
                        trapFocus: true,
                        onConfirm: (value) => {
                        
                            this.code = value

                            this.$buefy.toast.open(
                                { duration: 2500, message: `Hurray, successfully signed up!, Kindly wait to be redirected!` })
                        }
                    })
                    alert(JSON.stringify(this.form))

                }, 1.2 * 1000)

                

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.auth {
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 460px;
    margin: auto;
    // margin-top: 3.5rem;
    text-align: left;
}

// input{
//     padding: 1.5rem;
//     margin: 0.75em auto;
// }

// .title {
//     font-weight: 700;
// }

// .subtitle {
//     color: rgba(125, 125, 125, 0.7)
// }

// button {
//     padding: 12px 35px;
//     border-radius: 5px;
// }
</style>