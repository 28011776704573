<template>
    <div>
            <div class="pt-2 pb-4">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="shop in shops" :key="shop.id" class="column is-mobile is-half-tablet is-one-third-desktop">

                        <a class="" >
                            <!-- <div @click="routeTo(shop)" class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                                <b-image :src="shop.logo"></b-image>
                            </div> -->
                            <div @click="routeTo(shop)" class="">
                                <p class="mb-2 has-text-weight-semibold">{{ shop.shop }}</p>
                                <h6 class="small has-text-grey">My Preference</h6>
                                <!-- <b-tag class="has-text-grey ">Preferences</b-tag> -->
                                <!-- <p class="pt-2">{{ shop.comment }}</p> -->
                                <div v-if="shop.preferences.length > 0" class="pt-3 ">
                                    <b-button type="is-primary" outlined rounded class="mr-2 mb-3" v-for="pref in shop.preferences" :key="pref" size="is-small">{{ pref }}</b-button>
                                </div>
                                <span class="mr-1 ml-1 has-text- small "> {{ formatAMPM(shop.updatedAt) }} </span>
                            </div>
                                <!-- <span class="has-text-grey small "> {{ shop.locationName }} </span> -->
                            
                        </a>

                        <hr class="m-0 p-0 mb-0">
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'MyShopTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            shops: {
                required: true
            },
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'shop', 
                                    query: {  shop: data.shop }
                                })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
        } 
    }
</script>

<style lang="scss" scoped>// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .list-title {
        font-size: 0.95rem;
    }

    .columnxs {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>