
import axios from "axios";

const initialState = { visits: [] }

export const visits = {
    namespaced: true,
    state: initialState,
    getters: {
        getVisits(state) {
            return  state.visits
        },
        getVisitLink(state) {
            return  state.link
        },
        getVisit: state => (shop) => {
            return state.visits.find(({visit}) => visit.shop.includes(shop))
        },
        searchVisit: state => (shop) => {
            return state.visits.filter(({visit}) => visit.shop.includes(shop))
        },
        searchMainVisit: state => (search) => {
            
            const results = state.visits.filter(({ shop, comment, rate, suggestion, date }) => shop.toLowerCase().includes(search.toLowerCase()) || comment.toLowerCase().includes(search.toLowerCase()) || suggestion.toLowerCase().includes(search.toLowerCase()) || rate == search || date.toLowerCase().includes(search.toLowerCase()) )

            // const allVisits =  results.map(product => product.business)
            // const shops  = [...new Set(allVisits)]

            return results
        },
        
    },
    actions: {
        getVisit({ commit }, shop) {
            // get latest update in local storage
            // commit('updateVisit')
            let data
            let link = shop ? "/shop/visit?shop="+shop : "/shop/visit"
            
            //
            axios.get(link).then((res)=>{
                //
                if (res.data) {
                    //
                    data = res.data
                        //
                        for (let index = 0; index < data.length; index++) {
                            // const element = array[index];
            
                            
                            // sum ratings
                            const formatted = formatAMPM(data[index].createdAt)
                            // to 2 decimalplaces
                            data[index].date = formatted
            
                            // data.push(data[index])
                            
                        }
                    //
                    commit("updateVisit", data)
                }
            })
        },
        // getAllVisits({ state, commit }) {

        //     let data

        //     if (state.address !== "") {

        //         const loc = [state.location.lat, state.location.lng]
                
        //         axios.post("/shop/location", { location: loc } ).then((res)=>{
        //             //
        //             if (res.data) {
        //                 //
        //                 data = res.data
        //                 //
        //                 for (let index = 0; index < data.length; index++) {
        //                     // const element = array[index];
        //                     const rateLen = data[index].ratings.length
            
        //                     if (rateLen > 0) {
        //                     // sum ratings
        //                     const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
        //                     // to 2 decimalplaces
        //                     data[index].rate = parseFloat(rate.toFixed(1))
            
        //                     // data.push(data[index])
        //                     }
        //                 }
        //                 //
        //                 commit("updateVisit", res.data)
        //             }
        //         })

        //     } else {
                
        //         axios.get("/shop/").then((res)=>{
        //             //
        //             if (res.data) {
        //                 //
        //                 data = res.data
        //                 //
        //                 for (let index = 0; index < data.length; index++) {
        //                     // const element = array[index];
        //                     const rateLen = data[index].ratings.length
            
        //                     if (rateLen > 0) {
        //                     // sum ratings
        //                     const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
        //                     // to 2 decimalplaces
        //                     data[index].rate = parseFloat(rate.toFixed(1))
            
        //                     // data.push(data[index])
        //                     }
        //                 }
        //                 //
        //                 commit("updateVisit", res.data)
        //             }
        //         })
        //     }
            
        // },
    },
    mutations: {
        updateVisit:  (state, shops) => {
            //
            state.visits = shops
        },
    }
}


let formatAMPM = (date) => {
    date =  date ? new Date(date) : new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return date.toDateString()+', '+strTime;
}