<template>
    <div>
        <!-- <div class="">
            <router-link to="/index"> home </router-link>
        </div> -->
        <div class="p-5 pb-2">
            <h4 class="title is-4">Order Tracking {{ '-'+getNewOrder.deliveryTime.split('-')[1].split(' mins').join(':00') }}</h4>
            <h6 class="subtitle">{{ getNewOrder.business }} </h6>
            
            <b-steps
                ref="steps"
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode"
                :size="size"
                :vertical="isVertical">
                <b-step-item step="1" v-if="confirmed.displayed" :label="confirmed.time" :icon="confirmed.icon" :clickable="isStepsClickable" style="padding-top: 5px">
                    <!-- <h1 class="title has-text-centered">Account</h1> -->
                    <h6> <i class="loader mr-2" v-if="confirmed.active"></i> Order confirmed </h6>
                    <!-- <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading> -->
                </b-step-item>

                <b-step-item step="2" v-if="assigned.displayed" :label="assigned.time" :icon="assigned.icon" :clickable="isStepsClickable" >
                    <!-- <h1 class="title has-text-centered" style="color:white">Profile</h1> -->
                    <h6> <i class="loader mr-2" v-if="assigned.active"></i> Courier assigned </h6>
                    
                </b-step-item>

                <!-- <b-step-item step="3" :icon="picked.icon" :label="picked.time" :clickable="isStepsClickable">
                    <h6>Courier picked order</h6>
                </b-step-item> -->

                <b-step-item step="3" v-if="delivery.displayed" :icon="delivery.icon" :label="delivery.time" :clickable="isStepsClickable" >
                    <!-- <h1 class="title has-text-centered" style="color:white">Finish</h1> -->
                    <h6> <i class="loader mr-2" v-if="delivery.active"></i> Courier deliverying order</h6>
                </b-step-item>

                <b-step-item step="4" v-if="delivered.displayed" :icon="delivered.icon" :label="delivered.time" :clickable="isStepsClickable" style="padding-bottom: 5px;">
                    <!-- <h1 class="title has-text-centered" style="color:white">Finish</h1> -->
                    <h6> <i class="loader mr-2" v-if="delivered.active"></i> Order Delivered</h6>
                </b-step-item>

                <b-step-item step="5" v-if="scheduled.displayed" :icon="scheduled.icon" :label="scheduled.time" :clickable="isStepsClickable" style="padding-bottom: 5px;">
                    <!-- <h1 class="title has-text-centered" style="color:white">Finish</h1> -->
                    <h6> <i class="loader mr-2" v-if="scheduled.active"></i> Order scheduled</h6>
                </b-step-item>
                
                <!-- <div>
                    <h6>Vendor has confirmed your order.</h6>
                    <h6>A courier has been assigned for pickup.</h6>
                    <h6>Courier has picked your order for delivery.</h6>
                    <h6>Courier is deliverying your order .</h6>
                </div> -->

                <template
                    v-if="customNavigation"
                    #navigation="{previous, next}">
                    <b-button
                        outlined
                        type="is-danger"
                        icon-pack="fas"
                        icon-left="backward"
                        :disabled="previous.disabled"
                        @click.prevent="previous.action">
                        Previous 
                    </b-button>
                    <b-button
                        outlined
                        type="is-success"
                        icon-pack="fas"
                        icon-right="forward"
                        :disabled="next.disabled"
                        @click.prevent="next.action">
                        Next
                    </b-button>
                </template>
            </b-steps>

            <div class="pt-4">
                <h4 class="title is-4"> Order {{ getNewOrder.orderNo }}</h4>
            </div>

            
            <CartTemplate :products="items" :isControlVisible="false" :currency="getBusiness.country.currency"/>

            <div v-if="items.length > 0" class="card p-3">
                <!-- <h4 class="title is-5 p-2 pt-3">Total</h4>

                <div class="px-2">
                    <div class="columns is-mobile mb-0">
                        <div class="column">
                            <p class="has-text-weight-semibold">Subtotal</p>
                        </div>
                        <div class="column has-text-right">
                            <p class="has-text-weight-semibold">{{ getBusiness.country.currency }}{{ (totalPrice).toFixed(2) }}</p>
                        </div>
                    </div>
                </div> -->
                <div class="columns is-mobile ">
                    <div class="column">
                        <p class="has-text-weight-bold is-5">Total</p>
                    </div>
                    <div class="column has-text-right">
                        <p class="has-text-weight-bold is-5">{{ getBusiness.country.currency }}{{ (getBusiness.freeDelivery ? getNewOrder.totalPrice : getNewOrder.totalPrice + getBusiness.deliveryFee).toFixed(2) }}</p>
                    </div>
                </div>
            </div>

            <b-modal v-if="isModalActive" id="ratingModal" v-model="isModalActive" full-screen scroll="keep">
            <div class="p-2 rate text-center">
                <div class="rate-item">
                    <h4 class="title is-4">Rate your Order</h4>
                    <b-rate 
                        icon-pack="fas" 
                        v-model="rate"
                        size="is-large"
                        >
                    </b-rate>
                    <b-button class="is-success my-3" size="is-" v-if="rate > 0" @click="submitRate"> Done </b-button>
                </div>
            </div>
        </b-modal>
            <!-- {{ rate }} -->
        </div>
    </div>
</template>

<script>
    
    import axios from 'axios'
    import CartTemplate from '../components/CartTemplate'
    import { mapState, mapGetters } from 'vuex'

    export default {
        name: 'TrackerPage',
        data() {
            return {
                isModalActive: false,
                rate: 0,
                isLoading: false,
                loader: true,
                activeStep: null,
                isVertical: true,
                isAnimated: false,
                isRounded: true,
                isStepsClickable: false,
                size: 'is-small',
                hasNavigation: false,
                customNavigation: false,
                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right',
                labelPosition: 'left',
                mobileMode: 'compact',
                cancelled: null,
                confirmed: {
                    time: '',
                    icon: '',
                    displayed: true,
                    active: true
                },
                assigned: {
                    time: '',
                    icon: '',
                    displayed: true,
                    active: false
                },
                // picked: {
                //     time: '',
                //     icon: 'minus',
                //     active: false
                // },
                delivery: {
                    time: '',
                    icon: '',
                    displayed: true,
                    active: false
                },
                delivered: {
                    time: '',
                    icon: '',
                    displayed: true,
                    active: false
                },
                scheduled: {
                    time: '',
                    icon: '',
                    displayed: false,
                    active: false
                }
            }
        },
        computed: {
            ...mapState('orders', { items: state=> state.newOrder.items }),
            ...mapGetters('orders', ['getNewOrder']),
            getBusiness() {
                return this.$store.getters['vendors/currentBusiness'](this.getNewOrder.business)
            },
            user() {
                return this.$store.getters['auth/getUser'].phone
            }
        },
        components: {
            CartTemplate,
            // GoogleMaps
        },
        mounted() {
            
            // const tracker = setInterval(()=>{

            //     //
            //     this.isLoading = true

            //     // setTimeout(()=>{
            //     //     this.isLoading = false
            //     // }, 3000)

            //     // let step = this.$refs.steps.activeItem._props.step;

            //     // console.log(this.$refs.steps)

            //     switch (this.activeStep) {
            //         case 0:

            //             this.confirmed.icon = 'check'
            //             this.confirmed.time = '10:25 am'
            //             this.confirmed.active = false
            //             this.assigned.active = true
            //             this.$refs.steps.next()

            //             break;

            //         case 1:
            //             this.assigned.icon = 'check'
            //             this.assigned.time = '10:30 am'
            //             this.assigned.active = false
            //             this.delivery.active = true
            //             this.$refs.steps.next()

            //             break;

            //         // case 2:
            //         //     this.picked.icon = 'check'
            //         //     this.picked.time = '10:35 am'
            //         //     this.$refs.steps.next()

            //         //     break;

            //         case 2:
            //             this.delivery.icon = 'check'
            //             this.delivery.time = '10:38 am'
            //             this.delivery.active = false
            //             this.delivered.active = true
            //             this.$refs.steps.next()

            //             break;

            //         case 3:
            //             this.isLoading = false
            //             this.delivered.icon = 'check'
            //             this.delivered.time = '10:40 am'
            //             this.delivered.active = false
                        
            //             this.$refs.steps.next()
            //             //
            //             this.$buefy.toast.open('Hurrey, your order is delivered!')

            //             this.isModalActive = true
            //             // clear
            //             clearInterval(tracker)
            //             break;
                
            //         default:
            //             break;
            //     }

            // }, 5000)

            // this.$refs.steps.next()
            // this.$refs.steps.next()
            // console.log(this.$refs.steps)
            // this.$refs.steps.next()
            // console.log(this.$refs.steps.activeItem._props.step)
            
            // init watch for active step: create interval
            // this.activeStep = 0
            //
            // if (this.getNewOrder.scheduled) {
            //     //
            //     this.assigned.displayed = false
            //     this.delivery.displayed = false
            //     this.delivered.displayed = false
            //     this.scheduled.displayed = true
            // }
            //
            this.checkStatus()
            //
            this.initCheck()
        },
        watch: {
            // activeStep() {
            //     //
            //     clearInterval(this.initCheck())
            //     //
            //     this.initCheck()
            //     //
            //     console.log('changed')
            // }
            isModalActive() {
                if (this.isModalActive == false){
                    //
                    this.$router.push('/index')
                    //
                    this.$store.dispatch('orders/clearOrder')
                }
            }
        },
        methods: {
            //
            checkOrderTracking(step, time) {

                if (this.$route.name !== 'tracker') {
                    return
                }

                switch (step) {
                    case 1:

                        this.confirmed.icon = 'check'
                        this.confirmed.time = time
                        this.confirmed.active = false
                        this.assigned.active = true
                        this.$refs.steps.next()

                        break;

                    case 2:
                        this.assigned.icon = 'check'
                        this.assigned.time = time
                        this.assigned.active = false
                        this.delivery.active = true
                        this.$refs.steps.next()

                        break;

                    // case 2:
                    //     this.picked.icon = 'check'
                    //     this.picked.time = '10:35 am'
                    //     this.$refs.steps.next()

                    //     break;

                    case 3:
                        this.delivery.icon = 'check'
                        this.delivery.time = time
                        this.delivery.active = false
                        this.delivered.active = true
                        this.$refs.steps.next()

                        break;

                    case 4:
                        this.isLoading = false
                        this.delivered.icon = 'check'
                        this.delivered.time = time
                        this.delivered.active = false
                        
                        this.$refs.steps.next()
                        //
                        this.$buefy.toast.open('Hurrey, your order is delivered!')

                        this.isModalActive = true

                        break;
                
                    default:
                        break;
                    }
            },
            checkOrderSchedule(step, time) {

                if (this.$route.name !== 'tracker') {
                    return
                }

                switch (step) {
                    case 1:

                        this.confirmed.icon = 'check'
                        this.confirmed.time = time
                        this.confirmed.active = false
                        this.scheduled.active = true
                        this.$refs.steps.next()

                        break;

                    case 5:
                        this.isLoading = false
                        this.scheduled.icon = 'check'
                        this.scheduled.time = time
                        this.scheduled.active = false
                        
                        this.$refs.steps.next()
                        //
                        this.$buefy.toast.open('Hurrey, your order is scheduled!')

                        setTimeout(()=>{
                            // clear order
                            // this.$store.dispatch('orders/clearOrder')
                            //
                            this.$router.push('/index')
                        }, 1500)

                        //
                        this.$store.dispatch('orders/clearOrder')

                        break;
                
                    default:
                        break;
                    }
            },
            submitRate() {
                //
                axios.post('/order/rate', { rate: this.rate, orderNo: this.getNewOrder.orderNo, vendor: this.getNewOrder.business, user: { countryCode: this.user.substr(0, 4), tel: this.user.substr(4) } }).then((response)=>{
                    //
                    this.$buefy.toast.open(response.data)
                    //
                    this.isModalActive = false

                }).catch(error => {
                    //
                    this.$buefy.toast.open(error.data)
                })
            },
            checkStatus() {
                //
                axios.post('/order/status', { orderNo: this.getNewOrder.orderNo, user: { countryCode: this.user.substr(0, 4), tel: this.user.substr(4) } }).then(({ data })=>{
                    
                    if (data.cancelled !== null) {
                        //
                        this.confirmed.icon = 'times'
                        this.confirmed.active = false
                        //
                        this.cancelled = true
                        //
                        this.$buefy.toast.open({ duration: 10000, message: data.message, type: "is-dark" })

                        setTimeout(()=> {
                            this.$router.push('/index')
                        }, 13000)
                    }
                    //
                    else if (data.confirmed !== null && !this.confirmed.time) {
                        //
                        const time = this.formatAMPM(data.confirmed)
                        //
                        this.getNewOrder.scheduled ? this.checkOrderSchedule(1, time) : this.checkOrderTracking(1, time)
                    }
                    else if (data.courierAssigned !== null && !this.assigned.time) {
                        //
                        const time = this.formatAMPM(data.courierAssigned)
                        this.checkOrderTracking(2, time)

                    }
                    else if (data.courierDelivering !== null && !this.delivery.time) {
                        //
                        const time = this.formatAMPM(data.courierDelivering)
                        this.checkOrderTracking(3, time)
                    }
                    else if (data.orderDelivered !== null && !this.delivered.time) {
                        //
                        const time = this.formatAMPM(data.orderDelivered)
                        this.checkOrderTracking(4, time)
                    } 
                    else if (data.scheduled !== null && !this.scheduled.time) {//
                        //
                        const time =  (new Date(data.scheduled).toDateString() == new Date().toDateString() ? '' : new Date(data.scheduled).toDateString().substring(4,10)+',')+' '+this.formatAMPM(data.scheduled)
                        this.getNewOrder.scheduled ? this.checkOrderSchedule(5, time) : this.checkOrderTracking(4, time)
                    }
                })
            },
            initCheck() {
                //
                const timer = setInterval(()=>{
                    //
                    this.checkStatus()

                    if (this.activeStep == 4) {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.activeStep == 5) {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.$route.name !== 'tracker') {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.cancelled) {
                        // final step
                        clearInterval(timer)
                    }

                }, 10*1000)

                // console.log(new Date)
            },
            formatAMPM(date) {
                date = new Date(date)
                var hours = date.getUTCHours();
                var minutes = date.getUTCMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return strTime;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .loader {
        float: left;
    }

    .step-item {
        display: block !important;
        // padding-bottom: 55px;
        padding: 20px;
        padding-left: 1px;
        padding-right: 5px;
        // padding-top: 15px;
    }

    .step-item h6 {
        font-size: 0.9rem;
    }

    .step-title {
        padding-right: 15px !important;
    }

    .rate {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        height: 98%;
    }

    .rate-item {
        display: flex;
        flex-flow: column !important;
    }
</style>