<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <feather tag="router-link" to="/" type="arrow-left" size="22" class="p-2 m-5 close"></feather>
        <div class="p-2" v-if="currentShop">
            <!-- `${require('@/assets/img/'+currentShop.image+'')}` -->
               
                
                <div class="card p-5 my-3">
                    <div @click="gotoShop" class="columns is-mobile p-3">
                        
                        <div class="column is-4">
                            <b-image
                                v-if="currentShop.logo"
                                :src="currentShop.logo" :alt="currentShop.name"
                                :ratio="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? '4by3' : '5by3'))"
                            ></b-image>
                        </div>
                        <div class="column ">
                            <h3 class="title is-4 mb-2">{{ currentShop.name }}</h3>
                            <p class="has-text-grey mb-3"> {{ currentShop.locationName }}  </p>
                            <b-field>
                                <b-tag type="is-primary" class="mb-1 has-text-" >{{ currentShop.type }}</b-tag>
                            </b-field>
                            <b-field v-if="currentShop.rate">
                                <span class="mx-1 small has-text-weight-medium">{{ currentShop.rate }}</span>
                                <b-rate
                                    :value="currentShop.rate"
                                    :icon-pack="packs"
                                    :icon="icons"
                                    :max="maxs"
                                    :size="'is-small'"
                                    :locale="locale"
                                    :show-score="score"
                                    :custom-text="custom"
                                    :show-text="text"
                                    :texts="texts"
                                    :rtl="isRtl"
                                    :spaced="isSpaced"
                                    :disabled="true">
                                </b-rate>
                            </b-field>
                        </div>
                    </div>
                    
                </div>

                <div class="card p-5 my-3">
                    <h3 class="title is-6 mb-5 mx-2">Shopping experience</h3>
                    <b-field type="" message="Rate shopping">
                        <!-- <span class="mx-1 has-text-weight-medium">{{ rate }}</span> -->
                        <b-rate
                                v-model="rate"
                                :icon-pack="packs"
                                :icon="icons"
                                :max="maxs"
                                :size="sizes"
                                :locale="locale"
                                :show-score="score"
                                :custom-text="custom"
                                :show-text="text"
                                :texts="texts"
                                :rtl="isRtl"
                                :spaced="isSpaced"
                                :disabled="isDisabled">
                        </b-rate>
                    </b-field>
                    <b-field type="" message="Comment ">
                        <b-input maxlength="100" type="input" v-model="comment" placeholder="Enter comment"></b-input>
                    </b-field>

                    <b-field type="" message="Suggestion ">
                        <b-input maxlength="200" type="textarea" v-model="suggestion" placeholder="Your suggestion"></b-input>
                    </b-field>


                    <b-field class="pt-1">
                        <b-button v-if="!alreadySet" @click="onSubmit" rounded class="is-submit-button" expanded type="is-primary">{{ editVisit ? 'Update' : 'Submit' }}</b-button>
                    </b-field>
                </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'

    export default {
        name: "RateShopPage",
        data() {
            return {
                isSwitchedCustom: false,
                selected: [],
                rate: null,
                comment: "",
                suggestion: "",
                screenWidth: 0,
                editVisit: null,
                alreadySet: false,
                // currentShop: {
                //     id: '',
                //     name: '',
                //     cover: '', 
                //     phone: '', 
                //     logo: '',
                //     locationName: '', 
                //     location: '',
                //     ratings: '',
                //     type: '',
                //     website: '',
                //     categories: '',
                //     available: 0,
                //     active: 1,
                // },
                maxs: 5,
                sizes: 'is-large',
                packs: 'mdi',
                icons: 'star',
                score: false,
                custom: '',
                text: false,
                texts: ['Very bad', 'Bad', 'Good', 'Very good', 'Awesome'],
                isRtl:false,
                isSpaced: true,
                isDisabled: false,
                locale: undefined // Browser locale
            }
        },
        computed: {
            currentShop() {
                return this.$store.getters['shops/getShops'][0]
            },
            getLink() {
                return this.$store.getters['shops/getShopLink']
            }
        },
        mounted() {
            // validate
            if (this.getLink == null){
                //
                this.$router.push('/')
            } else {
                this.checkVisit()
            }

            //
            // this.getShops()

        },
        methods:{
            //
            checkVisit() {
                //
                axios.get('/shop/visit/'+this.currentShop.name).then(response=>{
                    //

                    // this.$buefy.dialog.confirm({
                    //     title: 'Shopping experience',
                    //     message: 'Already set experience, would you like to update?',
                    //     confirmText: 'Yes',
                    //     onConfirm: () => {
                    //         //
                    //         console.log(response.data)
                    //         //
                    //         this.editVisit = response.data.shop

                    //     },
                    //     cancelText: 'No',
                    //     onCancel: () => {
                    //         //
                    //         this.$buefy.toast.open("Wait to Review your shopping experience today.")
                    //         //
                    //         setTimeout(()=>{
                    //             this.$router.push('rateshop')
                    //         }, 4500)
                    //     }
                    // })


                    this.$buefy.toast.open("Already set shopping experience for today!")
                    //
                    // this.editVisit = response.data.shop
                    this.alreadySet = true
                    this.rate = response.data.rate
                    this.comment = response.data.comment
                    this.suggestion = response.data.suggestion

                    setTimeout(()=>{
                        //
                        this.$router.push({ name: 'shop', params: this.currentShop })
                        

                    }, 1500)
                    //

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            //
            checkPref(event) {
                //
                if (this.isSwitchedCustom) {
                    const all = this.currentShop.categories
                    //
                    this.selected = all
                } 
                
                if (this.isSwitchedCustom == false) {
                    this.selected = []
                }

            },
            getShops() {
                this.$store.dispatch('shops/getShop', this.getLink.shop ).then(()=>{
                    //
                    setTimeout(()=>{

                        // this.$buefy.toast.open("")
                        //
                        this.checkVisit()
                    }, 500)
                })
            },
            gotoShop() {
                //
                this.$router.push({ name: 'shop', params: this.currentShop })
            },
            onSubmit(event) {
                // validate
                let method
                if (this.editVisit) {
                    method = axios.patch
                } else {
                    method = axios.post
                }
                
                // ajax
                method('/shop/visit', { shop: this.currentShop.name, rate: this.rate, comment: this.comment, suggestion: this.suggestion } ).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)

                    //
                    this.$store.dispatch('shops/getShop', { shop: this.getLink.shop, type: "link" } )

                    // rate the service
                    setTimeout(()=>{
                        //
                        this.$router.push({ name: 'shop', params: this.currentShop })

                        //
                        setTimeout(()=>{
                            //
                            this.$store.dispatch('shops/clearLink')
                            
                        }, 500)
                        // 

                    }, 1500)


                }).catch(error=>{
                    //
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                //

                

                

                // alert(JSON.stringify(this.form))
            }
        }
    }
</script>

<style lang="scss" >
@import "vue-select/dist/vue-select.css";

</style>