<template>
    <div>
            <div class="pt-2 pb-4">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="visit in visits" :key="visit.id" class="column is-mobile is-half-tablet is-one-third-desktop">

                        <a class="columns  is-mobile mb-0" >
                            <!-- <div @click="routeTo(shop)" class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                                <b-image :src="shop.logo"></b-image>
                            </div> -->
                            <div @click="routeTo(visit)" class="column">
                                <p class="mb-2 has-text-weight-semibold">{{ visit.shop }}</p>
                                
                                <b-tag  class="mr-1 has-text-primary "> {{ formatAMPM(visit.createdAt) }} </b-tag>
                                <p class="pt-2">{{ visit.comment }}</p>
                                <p v-if="visit.response" class="pt-2 has-text-primary ">
                                    <!-- <b-icon size="is-small" type="is-dark" icon="message-reply" class="mr-2"></b-icon> -->
                                    <feather size="17" class="has-text-dark mr-2" type="corner-down-right"></feather>
                                    {{ visit.response }}</p>
                                <!-- <span class="has-text-grey small "> {{ shop.locationName }} </span> -->
                            </div>
                            <div class="column is-4 is-flex is-justify-content-center is-align-items-center">
                                <!-- <a class="p-2" :href="`tel:${shop.phone.countryCode+shop.phone.tel}`">
                                    <feather size="20" class="has-text-" type="phone-call"></feather>
                                </a> -->
                                <b-field>
                                    <!-- <b-tag class="mb-1 has-text-primary">{{ shop.type }}</b-tag>    -->
                                    <b-rate
                                        class=""
                                        :value="visit.rate"
                                        :max="5"
                                        :size="'is-small'"
                                        :show-score="true"
                                        :disabled="true">
                                    </b-rate>
                                    <!-- <br> -->
                                </b-field>
                            </div>
                        </a>

                        <hr class="m-0 p-0 mb-0">
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'VisitTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            visits: {
                required: true
            },
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'shop', 
                                    query: {  shop: data.shop }
                                })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
        } 
    }
</script>

<style lang="scss" scoped>// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .list-title {
        font-size: 0.95rem;
    }

    .columnxs {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>