<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                tag="router-link"
                to="/"
                style="top:0;position:absolute"
            ></icon> --><feather @click="$router.back()" size="30" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 mb-5 pl-5">Alerts <b-tag v-if="allAlerts.length > 0 " type="is-primary">{{ allAlerts.length }}</b-tag></h4>
            <div>
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSearch" @keyup.native="load" :loading="loading" v-model="search" placeholder="Search anything"></b-input>
                        </b-field>
                    </div>
            </div>
            
            <AlertTemplate :alerts="data.length > 0 ? data : allAlerts" :showOnlyUnread="false"></AlertTemplate>
            
            
            <!-- <b-button v-if="addressChanged" @click="redirect"> Done </b-button> -->
           
        </div>

        <MainFooter />
        
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import AlertTemplate from '../components/AlertTemplate.vue'
    import MainFooter from '../components/footer.vue'
    import axios from 'axios'

    export default {
        name: 'AlertsPage',
        data() {
            return {
                data: [],
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                search: ''
            }
        },
        components: {
            AlertTemplate,
            MainFooter
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
        

            allAlerts() {
                return this.$store.getters['alert/getAlerts']
            },

        },
        watch: {
            search() {
                if (this.searchString.length == 0) {
                    this.data = []
                }
            },
        },
        mounted() {
            this.getShops();
        },
        methods: {
            getShops() {
                this.$store.dispatch('alert/getAlert')
            },
            checkAlerts() {
                //
                axios.get('/alert/').then(response=>{
                    //
                    this.data = response.data
                    // this.visitRates = response.data.reduce((a, b) => a + b.rate, 0) !== 0 ? response.data.reduce((a, b) => a + b.rate, 0) / this.visits : 0

                    
                    // const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    // this.preferences = shop.preferences
                    // this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            redirect() {
                this.$router.back()
                // this.$router.push('home')
            },
            onSubmit() {
                if (this.searchString.length >= 3) {
                    console.log(this.searchString)
                }
            },
            async onSearch() {
                // console.log('here')
                if (this.search.length >= 0) {
                    
                   
                    const result = await this.$store.getters['alert/searchMainAlert'](this.search)

                    this.data = result
                    
                }
            }, 
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>