<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                tag="router-link"
                to="/"
                style="top:0;position:absolute"
            ></icon> -->
            <!-- <feather tag="router-link" size="30" to="/" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather> -->
            <!-- <h4 class="title is-4 ml-5 pl-5">Enter address</h4> -->
            <h4 class="title is-4 m-3 pb-0 mb-0">Enter address</h4>
            
            <GoogleMaps :height="'calc(100vh - 175px)'" style=""/>

            <div>
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSearch" @keyup.native="load" :loading="loading" v-model="search" placeholder="Search anything"></b-input>
                        </b-field>
                    </div>
            </div>
            
            <ShopTemplate :shops="data.length > 0 ? data : allShops"></ShopTemplate>
            
            <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
            <div v-else-if="!isLoading && allShops.length == 0" class="p-2">
                <div class="text-center p-4" style="max-width:300px;margin:auto">
                    
                    <!-- <h4 class="title is-5  "> !Oops, sorry we don't deliver here </h4> -->
                    <p class="has-text-weight-medium has-text-grey subtitle">We are currently in. </p>
                </div>
                <hr class="m-2">

                <ul v-if="mapLocations.length !== 0"  class="menu-list columns is-multiline is-mobile pt-1 px-1" style="">
                    <li v-for="loc in mapLocations" :key="loc.address" class="column is-half-mobile is-one-third-tablet is-one-fourth-desktop p-1">
                        <a @click="setAddress(loc)" class="my-1">
                            <p class="title is-6 is-flex is-align-items-center" style="font-weight: 400">
                            <feather type="map-pin" class="pr-3"> </feather>
                                    {{ loc.address.split(',')[0] }}</p>
                                    <p class="subtitle is-6 ml-5 pl-3">{{ loc.address.split(',')[1] }}</p>
                            </a>
                        <hr class="m-0 p-0">
                    </li>
                </ul>
            </div>
            <!-- <b-button v-if="addressChanged" @click="redirect"> Done </b-button> -->
            <div hidden>
                <div class="">
                    <div class="" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSubmit" @keyup.native="load" :loading="loading" autofocus v-model="searchString" placeholder="Enter an address "></b-input>
                        </b-field>
                    </div>
                </div>
                <ul class="menu-list" style="max-width:460px;">
                    <li>
                        
                        <a class="my-1">
                            <p class="title is-6" style="font-weight: 300">Spintex Coastal</p>
                            <p class="subtitle is-6">Kpeshie</p>
                        </a>
                        <hr class="m-0 p-0">
                    </li>
                    <li>
                        <a class="my-1">
                            <p class="title is-6" style="font-weight: 300">Airport City</p>
                            <p class="subtitle is-6">Accra</p>
                        </a>
                        <hr class="m-0 p-0">
                    </li>
                </ul>

            </div>
        </div>

        <MainFooter />
        
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import GoogleMaps from '../components/GoogleMaps'
    import ShopTemplate from '../components/ShopTemplate'
    import MainFooter from '../components/footer.vue'

    export default {
        name: 'mapView',
        data() {
            return {
                isLoading: true,
                data: [],
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                search: '',
                mapLocations: [
                    { address: 'Accra Mall, Tetteh Quarshie', location: {"lat":5.6219135,"lng":-0.1737838}},
                    { address: 'A N C Mall, East legon', location: {"lat":5.6415757,"lng":-0.1526023}},
                    { address: 'Junction Mall, Nungua Barrier', location: {"lat":5.6135117,"lng":-0.0746697}},
                    { address: 'West Hills Mall, Weija', location: {"lat":5.5468439,"lng":-0.3468371}},
                    { address: 'Marina Mall, Airport', location: {"lat":5.603856,"lng":-0.176897}},
                    { address: 'Melcom Mall, Spintex', location: {"lat":5.6342745,"lng":-0.1363526}},
                    { address: 'Palace Mall, Spintex', location: {"lat":5.6257548,"lng":-0.1538399}}
                ],
            }
        },
        components: {
            GoogleMaps,
            ShopTemplate,
            MainFooter
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
            
            address() {
                return this.$store.getters['shops/address']
            },

            allShops() {
                return this.$store.getters['shops/getShops']
            },

        },
        watch: {
            search() {
                if (this.searchString.length == 0) {
                    this.data = []
                }
            },
            address(){
                if (this.address.address){
                    
                    this.$buefy.toast.open('Successfull')
                    //
                    this.addressChanged = true;
                    
                    //
                    this.getShops();
                }
            }
        },
        mounted() {

            if (!this.address.address) {
                this.$buefy.toast.open('Kindly search for nearest Landmark or turn on location! ')
            }

            this.getShops();
        },
        methods: {
            getShops() {
                this.$store.dispatch('shops/getAllShops')
                this.isLoading = false
            },
            redirect() {
                this.$router.back()
                // this.$router.push('home')
            },
            onSubmit() {
                if (this.searchString.length >= 3) {
                    console.log(this.searchString)
                }
            },
            async onSearch() {
                // console.log('here')
                if (this.search.length >= 3) {
                    
                   
                    const result = await this.$store.getters['shops/searchMainShop'](this.search)

                    this.data = result
                    
                }
            }, 
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
            setAddress(loc) {
                //
                this.$store.dispatch('shops/addPlace', { address: loc.address, location: loc.location })
                //
                this.getShops()
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>