import sha256 from "sha256";
import axios from "axios";
import moment  from 'moment'

const offer = localStorage.getItem('offers') ? JSON.parse(localStorage.getItem('offers')) :  false;
const location = localStorage.getItem('location2') ? JSON.parse(localStorage.getItem('location2')) :  false;

const _offer = offer
    ? { offers: [], loggedOffers: offer }
    : { offers: [], loggedOffers: null };

const _location = location
    ? location
    : { location: '', address: '', addressList: [] };

const initialState = { ..._offer, ..._location }

export const offers = {
    namespaced: true,
    state: initialState,
    getters: {
        getOffers(state) {
            // return  state.offers

            const data = state.offers
            // data.sort((a,b) =>  a.name.localeCompare(b.name))

            return data
        },
        getOfferLink(state) {
            return  state.link
        },
        getOffer: state => (offer) => {
            return state.offers.find(({Offer}) => Offer.shop.includes(offer))
        },
        searchOffer: state => (offer) => {
            return state.offers.filter(({Offer}) => Offer.product.includes(offer))
        },
        searchMainOffer: state => (search) => {
            
            const results = state.offers.filter(({ product, category, shop, type, offerType, tags, details }) => 
                
            product.toLowerCase().includes(search.toLowerCase()) || 
            category.toLowerCase().includes(search.toLowerCase()) || 
            shop.toLowerCase().includes(search.toLowerCase()) || 
            type.toLowerCase().includes(search.toLowerCase()) || 
            offerType.toLowerCase().includes(search.toLowerCase()) || 
            details.toLowerCase().includes(search.toLowerCase()) || 
            tags.find((tag)=> tag.toLowerCase().includes(search.toLowerCase()))
            
            )

            // const allOffers =  results.map(product => product.business)
            // const offers  = [...new Set(allOffers)]

            return results
        },
        address: (state) =>  {
            return { address: state.address, location: state.location }
          },
        allAddresses: (state) => {
            //
            return state.addressList
          },
          getAddress: (state) => (name) => {

            
            const results = state.addressList.find(({address}) => address.toLowerCase().includes(name.toLowerCase()))
        
            return results
          },
          parseAddress: (addressObject) => {
            //
            const parsed = [addressObject.lat, addressObject.lng]

            return parsed
          },
        verifyOffer: (state) => (name, hash) =>  {
            
            //
            const secret = 225466879314

            if (sha256(name+secret) == hash) {
                return true
            } else {
                return false
            }
        },
        generateOfferHash: (state) => (name) => {
            const secret = 225466879314
            //
            return sha256(name+secret)
        },
        checkLoggedReq: (state) => ({ product, shop }) => {
            
            const find =  state.loggedOffers ? (state.loggedOffers.length > 0  ? state.loggedOffers.find(log=>log.product == product && log.shop == shop) : false) : false
            //
            return find
        }
    },
    actions: {
        async getOffers({ commit }, param) {
            // get latest update in local storage
            // commit('updateOffer')
            let data
            //
            await axios.get("offers").then((res)=>{
                //
                if (res.data) {
                    //
                    data = res.data
                    //
                    for (let index = 0; index < data.prefs.length; index++) {

                            data.prefs[index].pref = true

                            data.prefs[index].exp = data.prefs[index].end ?  Math.abs(moment(new Date(data.prefs[index].end)).diff(moment(new Date), 'days')) : null
                    }

                    for (let index = 0; index < data.data.length; index++) {
                        
                            
                        data.data[index].pref = false

                        data.data[index].exp = data.data[index].end ?  Math.abs(moment(new Date(data.data[index].end)).diff(moment(new Date), 'days')) : null
                    
                    }
                    
                    //
                    commit("updateOffer", [...data.prefs.reverse(),...data.data.reverse()])
                }
            })
        },
        async getShopOffers({ commit }, param) {
            // get latest update in local storage
            // commit('updateOffer')
            let data
            //
            await axios.get("/offers/"+encodeURIComponent(param)+"").then((res)=>{
                //
                if (res.data) {
                    //
                    data = res.data
                    //
                    for (let index = 0; index < data.length; index++) {
                        // const element = array[index];
                        
                        data[index].exp = data[index].end ?  Math.abs(moment(new Date(data[index].end)).diff(moment(new Date), 'days')) : null
                        // data.push(data[index])
                        
                    }
                    //
                    commit("updateOffer", res.data.reverse())
                }
            })
        },
        async getAllOffers({ state, commit }) {

            let data

            if (state.address !== "") {

                const loc = [state.location.lat, state.location.lng]
                
                await axios.post("/offer/location", { location: loc } ).then((res)=>{
                    //
                    if (res.data) {
                        //
                        data = res.data
                        //
                        for (let index = 0; index < data.length; index++) {
                            // const element = array[index];
                            
                            data[index].exp = data[index].end ?  Math.abs(moment(new Date(data[index].end)).diff(moment(new Date), 'days')) : null
                            // data.push(data[index])
                            
                        }
                        //
                        commit("updateOffer", res.data.reverse())
                    }
                })

            } else {

                // await axios.get("offers").then((res)=>{
                //     //
                //     if (res.data) {
                //         //
                //         data = res.data
                //         //
                //         for (let index = 0; index < data.prefs.length; index++) {
    
                //                 data.prefs[index].pref = true
    
                //                 data.prefs[index].exp = data.prefs[index].end ?  Math.abs(moment(new Date(data.prefs[index].end)).diff(moment(new Date), 'days')) : null
                //         }
    
                //         for (let index = 0; index < data.data.length; index++) {
                            
                                
                //             data.data[index].pref = false
    
                //             data.data[index].exp = data.data[index].end ?  Math.abs(moment(new Date(data.data[index].end)).diff(moment(new Date), 'days')) : null
                        
                //         }
                        
                //         //
                //         commit("updateOffer", [...data.prefs.reverse(),...data.data.reverse()])
                //     }
                // })
            }
            
        },
        addPlace({ state, commit }, data) {

          // console.log(data)

          if (typeof(data) === 'object' || data.address || data.location) {
            //
            const found = state.addressList.find((place)=> place.address.includes(data.address))

            if (state.address !== data.address) {
              //
              commit('addPlace', data)
            }
            
          }
        },
        clearLink({ commit }) {
            
            commit('clearLink')
        },
        async updateLoggedReq({ state, commit }, { product, shop }) {
            //
            if (product && shop) {

                // impression initiator
                await axios.post("/offer/impression", { product, shop } ).then((res)=>{
                    //
                    if (res.data) {
                        //
                        commit('updateLoggedReq', {product, shop})
                    }
                }).catch(err=>{
                    //
                    commit('updateLoggedReq', {product, shop})
                })
            }
            
        },
        async makeClickReq({ state, commit }, { product, shop }) {
            //
            if (product && shop) {

                // impression initiator
                await axios.post("/offer/click", { product, shop } ).then((res)=>{
                    //
                    // if (res.data) {
                        //
                        commit('updateClickReq', { product, shop })
                    // }
                });

                // commit('updateLoggedReq', {product, shop})
            }
            
        },
        async makeConversionReq({ state, commit }, { product, shop }) {
            //
            if (product && shop) {

                // impression initiator
                await axios.post("/offer/conversion", { product, shop } ).then((res)=>{
                    //
                    // if (res.data) {
                        //
                        commit('updateConversionReq', { product, shop })
                    // }
                });

                // commit('updateLoggedReq', {product, shop})
            }
            
        }
    },
    mutations: {
        updateOffer:  (state, offers) => {
            //
            state.offers = offers

            const logged = localStorage.getItem('offers') ? JSON.parse(localStorage.getItem('offers')) : false

            // check log
            if (logged && logged.length > 0) {
                
                // check date
                const date = (new Date()).toISOString().substring(0,10)

                // dates
                if (logged.filter(log=> log.date.includes(date))) {
                    // compare logged & offers
                    const diff = offers.filter(({ product: p1, shop: s1 }) => !logged.some(({ product: p2, shop: s2 }) => p2 == p1 && s2 == s1)).map(l => ({ "product": l.product, "shop": l.shop, "logged": false, "clicked": false, "converted": false, "date": (new Date()).toISOString().substring(0,10) }))
                    
                    // new offers
                    if (diff.length > 0) {
                        // merge
                        const merge  = [...logged, ...diff]
                        //
                        state.loggedOffers = merge
                        //
                        localStorage.setItem('offers', JSON.stringify(merge))
                    } else {
                        //
                        console.log("Already logged")
                    }

                } else {
                    // 
                    const log = offers.map(l => ({ "product": l.product, "shop": l.shop, "logged": false, "clicked": false, "converted": false, "date": (new Date()).toISOString().substring(0,10) }) )
                    //
                    state.loggedOffers = log
                    //
                    localStorage.setItem('offers', JSON.stringify(log))
                }

                //
            } else {
                //
                const log = offers.map(l => ({ "product": l.product, "shop": l.shop, "logged": false, "clicked": false, "converted": false, "date": (new Date()).toISOString().substring(0,10) }))
                //
                state.loggedOffers = log
                //
                localStorage.setItem('offers', JSON.stringify(log))
            }
        },
        updateLoggedReq: (state, { product, shop }) => {
            const offer = state.loggedOffers.find(log=>log.product == product && log.shop == shop)

            //
            offer.logged  = true

            //
            localStorage.setItem('offers', JSON.stringify(state.loggedOffers))

        },
        updateClickReq: (state, { product, shop }) => {
            const offer = state.loggedOffers.find(log=>log.product == product && log.shop == shop)

            //
            offer["clicked"]  = true

            //
            localStorage.setItem('offers', JSON.stringify(state.loggedOffers))
        },
        updateConversionReq: (state, { product, shop }) => {
            const offer = state.loggedOffers.find(log=>log.product == product && log.shop == shop)

            //
            offer["converted"]  = true

            //
            localStorage.setItem('offers', JSON.stringify(state.loggedOffers))
        },
        addPlace: (state, data) => {
          // set
          state.address = data.address
          state.location = data.location
          // check before push
          const found = state.addressList.find((place)=> place.address.includes(data.address))

          if (!found) {
            state.addressList.push(data)
          }

          localStorage.setItem('location2', JSON.stringify({ location: data.location, address: data.address, addressList: state.addressList }))
          
        },
        updateLink:  (state) => {
            
            //
            const offer = localStorage.getItem('offer-link') ? JSON.parse(localStorage.getItem('offer-link')) : false
            state.link = offer

            //
            localStorage.removeItem('offer-link')
        },
        clearLink: (state) => {
            
            //
            state.link = null
            localStorage.removeItem('offer-link')

        }
    }
}