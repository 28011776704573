<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                tag="router-link"
                to="/"
                style="top:0;position:absolute"
            ></icon> -->
            <feather @click="$router.back()" size="30" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 mb-5 pl-5">My shops <b-tag v-if="allShops.length > 0 " type="is-primary">{{ allShops.length }}</b-tag></h4>
            <div>
                    <div class="mx-2" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSearch" @keyup.native="load" :loading="loading" v-model="search" placeholder="Search anything"></b-input>
                        </b-field>
                    </div>
            </div>
            
            <MyShopTemplate :shops="data.length > 0 ? data : allShops"></MyShopTemplate>
            
            
            <!-- <b-button v-if="addressChanged" @click="redirect"> Done </b-button> -->
           
        </div>
        
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import MyShopTemplate from '../components/MyShopTemplate'
    import MainFooter from '../components/footer.vue'
    import axios from 'axios'

    export default {
        name: 'MyShopsPage',
        data() {
            return {
                data: [],
                allShops: [],
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                search: ''
            }
        },
        components: {
            MyShopTemplate,
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
        

            // allShops() {
            //     return this.$store.getters['shop/allShops']
            // },

        },
        watch: {
            search() {
                if (this.searchString.length == 0) {
                    this.data = []
                }
            },
            address(){
                if (this.address.address){
                    
                    this.$buefy.toast.open('Successfull')
                    //
                    this.addressChanged = true;
                    
                    //
                    this.getShops();
                }
            }
        },
        mounted() {
            this.checkShop();
        },
        methods: {
            // getShops() {
            //     this.$store.dispatch('visits/getVisit')
            // },
            checkShop() {

                axios.get("/user/pref").then((res)=>{
                    //
                    if (res.data) {
                        //
                        this.allShops = res.data.shops
                    }
                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })

            },
            redirect() {
                this.$router.back()
                // this.$router.push('home')
            },
            onSubmit() {
                if (this.searchString.length >= 3) {
                    console.log(this.searchString)
                }
            },
            async onSearch() {
                // console.log('here')
                if (this.search.length >= 0) {
                    
                   
                    // const result = await this.$store.getters['visits/searchMainVisit'](this.search)
                    const result = this.allShops.filter(({ shop, preferences }) => shop.toLowerCase().includes(this.search.toLowerCase()) || preferences.find((pref)=> pref.toLowerCase().includes(this.search.toLowerCase())) )

                    this.data = result
                    
                }
            }, 
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>