<template>
    <div>
        <div class="p-3">
            <div class="p-3 px-4">
                <h4 class="title is-4">My Orders</h4>
            </div>
        </div>
        <div>
            <!-- {{ getOrders }} -->
            <OrderTemplate :orders="getOrders"></OrderTemplate>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    
    import OrderTemplate from '../components/orderTemplate'

    export default {
        name: "OrdersPage",
        components: {
            MainFooter,
            OrderTemplate
        },
        computed: {
            //
            ...mapGetters('orders', ['getOrders'])
        },
        mounted() {
            this.getOrder()
        },
        methods: {
            getOrder() {
                this.$store.dispatch('orders/getOrders')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>