<template>
    <div>
            <div class="py-4">
                <ul class="menu-list columns is-multiline" style="">
                    <li v-for="offer in offers" :key="offer._id" v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }" class="column is-mobile is-half-tablet is-one-third-desktop" :id="offer._id">

                        <a :disabled="!offer.available"  :style="offer.available ? '' : 'opacity:0.7'" class="columns  is-mobile mb-0" >
                            <span @click="redirectTo(offer)" class="column is-one-third">
                                <b-image webp-fallback=".jpg" :lazy="true" ratio="4by3" :src="offer.image"></b-image>
                                <div class="has-text-centered" style="">
                                    <b-tag :type="`${ (offer.offerType == 'Discount' ? 'is-primary' : offer.offerType == 'Coupon' ? 'is-warning text-dark' : offer.offerType == 'Gift with Purchase' ? 'is-info' : offer.offerType == 'Promo' ? 'is-danger' : 'is-success') }`" class="mb-1"> {{ offer.offerType }}</b-tag> 
                                    <br><span class="small has-text-weight-semibold  pt-1">
                                        {{ offer.category }}
                                    </span>
                                </div>
                            </span>
                            <div @click="redirectTo(offer)" class="column">
                                <div :class=" `title is-6 has-text-weight-semibold ${offer.pref ? 'has-text-primary' : ''}`">{{ offer.product }} </div>
                                <p class="subtitle has-text-grey small mb-2"> {{ offer.shop }} </p>
                                
                                <p class="mb-3">
                                    <span  @click="redirectTo(offer)" v-if="offer.discount !== 0" class="mr-1">
                                        <span class=" has-text-primary has-text-weight-semibold" > {{ currency }}{{ formatPrice( getDiscount(offer.price, offer.discount) ) }} </span>
                                        
                                        <span class="mr-1 small has-text-grey" style="text-decoration:line-through"> {{ currency }}{{ formatPrice(offer.price) }} </span> 
                                    </span>
                                    <span class=" has-text- has-text-weight-medium" @click="redirectTo(offer)" v-else>{{ currency }}{{ formatPrice(offer.price) }}</span>

                                    <b-tag v-if="offer.limited" type="is-danger"> Limited </b-tag>
                                </p>
                                <div class="has-text-grey small is-flex"> 
                                    <b-tag :class="`mb-1 ${offer.available ? 'has-text-primary' : 'has-text-danger'}  `">  {{ (offer.available ? offer.details.length > 32 ? offer.details.substr(0,32)+'...' : offer.details : 'Out of stock') }}</b-tag>
                                </div>
                                <span v-if="offer.expiry" class="small has-text-dark has-text-weight-medium is-flex is-align-items-center ml-2">  <feather size="16" class="has-text-danger mr-1" type="clock"></feather> {{ (new Date(offer.end)).toDateString().substring(4,15) }} </span>
                                
                            </div>
                            <!-- <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center is-flex-direction-column"> -->
                                <!-- <a class="p-3" :href="`tel:${offer.phone.countryCode+offer.phone.tel}`">
                                    <feather size="20" class="has-text-" type="phone-call"></feather>
                                </a>
                                <a class="p-3" @click="routeToProduct(offer)">
                                    <feather size="20" class="has-text-" type="tag"></feather>
                                </a> -->
                            <!-- </div> -->
                        </a>

                        <hr class="m-0 p-0 mb-0">
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"

    export default {
        name: 'OfferTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0,
                intersectionOptions: {
                    root: null,
                    rootMargin: '30px 0px -105px 0px',
                    // threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
                }
            }
        },
        props: {
            offers: {
                required: true
            },
            currency: {

            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onWaypoint({ el, going, direction  }) {
                // console.log(going)
                // console.log(el.getAttribute('id'))
                const pro = this.offers.find(f=>f._id == el.getAttribute('id'))

                // console.log(going, this.offers.find(f=>f._id == el.getAttribute('id')).product, direction )
                // console.log(data)
                
                // product in view
                if (going === this.$waypointMap.GOING_IN ) {
                
                    const check = this.$store.getters["offers/checkLoggedReq"](pro)
                    // console.log(check)
                    
                    // if not logged
                    if (!check.logged) {
                        // console.log("Not logged")
                        this.$store.dispatch("offers/updateLoggedReq", { product: pro.product, shop: pro.shop })
                    }
                }

            },
            logImpression(data) {
                
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            routeTo(data) {

                //
                
                    //
                    this.$router.push({ name: 'offer', 
                                    params: { 
                                        id: data.id,
                                        name: data.name,
                                        cover: data.cover,
                                        phone: data.phone,
                                        country: data.country,
                                        whatsapp: data.whatsapp,
                                        logo: data.logo,
                                        locationName: data.locationName,
                                        location: data.location,
                                        rate: data.rate,
                                        type: data.type,
                                        website: data.website,
                                        categories: data.categories,
                                        available: data.available,
                                        active: data.active
                                    } 
                                })
                

            },
            redirectTo (currentProduct) {
                if (!currentProduct.available){
                    return
                }

                const check = this.$store.getters["offers/checkLoggedReq"](currentProduct)
                
                if (!check.clicked) {
                    // log 
                    this.$store.dispatch("offers/makeClickReq", { product: currentProduct.product, shop: currentProduct.shop })

                }

                this.$router.push({ name: 'product', 
                                    params: { 
                                        id: currentProduct._id,
                                        available: currentProduct.available,
                                        shop: currentProduct.shop, 
                                        image: currentProduct.image,
                                        category: currentProduct.category,
                                        product: currentProduct.product,
                                        tags: currentProduct.tags,
                                        discount: currentProduct.discount,
                                        price: currentProduct.price,
                                        type: currentProduct.type,
                                        details: currentProduct.details,
                                        description: currentProduct.description, 
                                        currency: this.currency,
                                        start: currentProduct.start,
                                        end: currentProduct.end,
                                        expiry: currentProduct.expiry,
                                        limited: currentProduct.limited,
                                        offerType: currentProduct.offerType,
                                    } 
                                })
            },
            routeToProduct(data) {
                    //
                    this.$router.push({ name: 'products', 
                                    params: { 
                                        id: data.id,
                                        name: data.name,
                                        cover: data.cover,
                                        phone: data.phone,
                                        country: data.country,
                                        whatsapp: data.whatsapp,
                                        logo: data.logo,
                                        locationName: data.locationName,
                                        location: data.location,
                                        rate: data.rate,
                                        type: data.type,
                                        website: data.website,
                                        categories: data.categories,
                                        available: data.available,
                                        active: data.active,
                                    } 
                                })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
        } 
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';
    
    .list-title {
        font-size: 0.95rem;
    }

    .columnxs {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }

    .menu-list > a {
        padding: 1rem 0.75rem !important;
    }
</style>