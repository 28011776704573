var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-header"}),_c('div',{staticClass:"page-body-wrapper"},[_c('div',{staticClass:"page-body"},[_c('transition',{attrs:{"enter-active-class":""}},[_c('div',{},[(_vm.getUser.thereturn)?_c('img',{style:(`
                    opacity: 1;
                    position: absolute;
                    height: 2%;
                    width: 100%;
                    background-color: black;
                    object-fit: cover;
                    border-radius: 0 !important;`),attrs:{"src":`${require('@/../public/african.jpg')}`,"alt":""}}):_vm._e(),_c('router-view',{staticClass:"view"})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }