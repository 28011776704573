<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <feather tag="router-link" to="/" type="arrow-left" size="22" class="p-2 m-5 close"></feather>
        <div class="p-2" v-if="currentShop">
            <!-- `${require('@/assets/img/'+currentShop.image+'')}` -->
               
                <!-- <b-image
                    v-if="currentShop.logo"
                    :src="currentShop.logo" :alt="currentShop.name"
                    :ratio="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? '4by3' : '5by3'))"
                ></b-image>
                <div class="card p-5 my-3">
                    <div class="p-3">
                            <h3 class="title is-4 mb-2">{{ currentShop.name }}</h3>
                            <p class="has-text-grey mb-3"> {{ currentShop.locationName }}  </p>
                            <b-field>
                                <b-tag type="is-primary" class="mb-1 has-text-" >{{ currentShop.type }}</b-tag>
                            </b-field>
                            <b-field v-if="currentShop.rate">
                                <span class="mx-1 small has-text-weight-medium">{{ currentShop.rate }}</span>
                                <b-rate
                                    :value="currentShop.rate"
                                    :max="5"
                                    :size="'is-small'"
                                    :spaced="true"
                                    :disabled="true">
                                </b-rate>
                        </b-field>
                    </div>
                    
                </div> -->
                <div class="card p-5 my-3">
                    <div @click="gotoShop" class="columns is-mobile p-3">
                        
                        <div class="column is-4">
                            <b-image
                                v-if="currentShop.logo"
                                :src="currentShop.logo" :alt="currentShop.name"
                                :ratio="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? '4by3' : '5by3'))"
                            ></b-image>
                        </div>
                        <div class="column ">
                            <h3 class="title is-4 mb-2">{{ currentShop.name }}</h3>
                            <p class="has-text-grey mb-3"> {{ currentShop.locationName }}  </p>
                            <b-field>
                                <b-tag type="is-primary" class="mb-1 has-text-" >{{ currentShop.type }}</b-tag>
                            </b-field>
                            <b-field v-if="currentShop.rate">
                                <span class="mx-1 small has-text-weight-medium">{{ currentShop.rate }}</span>
                                <b-rate
                                    :value="currentShop.rate"
                                    :size="'is-small'"
                                    :disabled="true">
                                </b-rate>
                            </b-field>
                        </div>
                    </div>
                    
                </div>

                <div class="card p-5 my-3">
                    <h3 class="title is-6 mb-5 mx-2">Set Shopping profile</h3>

                    <b-field label="" type="" message="Select preferences">
                        <v-select  multiple v-model="selected" :options="currentShop.categories" label="Preferences"></v-select>
                    </b-field>
                    <b-field>
                        <b-switch @input="checkPref" v-model="isSwitchedCustom"
                            >
                            Select all preferences
                        </b-switch>
                    </b-field>

                    <!-- {{ currentShop.categories }} -->

                    <!-- {{ selected }} -->

                    <b-field class="pt-1">
                                <b-button @click="onSubmit" rounded class="is-submit-button" expanded type="is-primary"> {{ editShop ? 'Update' : 'Submit' }} </b-button>
                    </b-field>
                </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'

    export default {
        name: "SetupPrefPage",
        data() {
            return {
                isSwitchedCustom: false,
                selected: [],
                screenWidth: 0,
                editShop: null,
                fromShop: false,
                shop: null,
                // currentShop: {
                //     id: '',
                //     name: '',
                //     cover: '', 
                //     phone: '', 
                //     logo: '',
                //     locationName: '', 
                //     location: '',
                //     ratings: '',
                //     type: '',
                //     website: '',
                //     categories: '',
                //     available: 0,
                //     active: 1,
                // },
            }
        },
        computed: {
            currentShop() {
                let from = this.$route.params.shop ? this.$route.params.shop : false
                return from ? this.shop : this.$store.getters['shops/getShops'][0]
                // return this.$store.getters['shops/getShops'][0]
            },
            getLink() {
                return this.$store.getters['shops/getShopLink']
            }
        },
        mounted() {
            // validate

            

            console.log(this.$route.params)

            if (Object.keys(this.$route.params).length !== 0) {

                console.log(this.$route.params)
                //
                this.shop = this.$route.params.shop
                this.editShop = this.$route.params.editPref
                this.fromShop = true

                if (this.editShop && this.$route.params.prefs){
                    this.selected = this.$route.params.prefs

                    this.$buefy.toast.open("Kindly update shopping preference.")

                } else {
                    this.$buefy.toast.open("Set shopping profile below.")
                }

            } else {
                
                

                console.log(this.getLink)
                if (this.getLink == null){
                    //
                    this.$router.push('/')
                    // console.log("here")
                } else {
                    //
                    
                    this.checkShop()

                }
                //
                
            }

        },
        methods:{
            //
            checkShop() {
                //
                axios.get('/user/pref/'+this.currentShop.name).then(response=>{
                    //

                    // this.$buefy.dialog.confirm({
                    //     title: 'Shopping preference',
                    //     message: 'Preferences are already set, would you like to update?',
                    //     confirmText: 'Yes',
                    //     onConfirm: () => {
                    //         //
                            console.log(response.data)
                    //         //
                    //         this.editShop = response.data.shops[0].id
                            this.selected = response.data.shops[0].preferences

                    //     },
                    //     cancelText: 'No',
                    //     onCancel: () => {
                            //
                            this.$buefy.toast.open("Wait to Review your shopping experience today.")
                            //
                            setTimeout(()=>{
                                this.$router.push('rateshop')
                            }, 1000)
                        // }
                    // })
                    //

                }).catch(error=>{
                    //
                    this.$buefy.toast.open("Set shopping preference below.")
                })
            },
            //
            checkPref(event) {
                //
                if (this.isSwitchedCustom) {
                    const all = this.currentShop.categories
                    //
                    this.selected = all
                } 
                
                if (this.isSwitchedCustom == false) {
                    this.selected = []
                }

            },
            gotoShop() {
                //
                this.$router.push({ name: 'shop', params: this.currentShop })
            },
            getShops() {
                this.$store.dispatch('shops/getShop', this.getLink.shop ).then(()=>{
                    //
                    setTimeout(()=>{
                        this.checkShop()

                        // localStorage.removeItem('shop-link')
                    }, 500)
                })
            },
            onSubmit(event) {
                // validate
                let method
                if (this.editShop) {
                    method = axios.patch
                } else {
                    method = axios.post
                }
                
                // ajax
                method('/user/pref/', { prefs: this.selected, shop: this.currentShop.name, id: this.editShop } ).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)

                    // rate the service
                    setTimeout(()=>{
                        
                        if (this.shop) {
                            //
                            this.$router.push({ name: 'shop', params: this.shop })

                        } else {
                            //
                            this.$router.push('rateshop')
                        }

                    }, 2000)


                }).catch(error=>{
                    //
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                //

                

                

                // alert(JSON.stringify(this.form))
            }
        }
    }
</script>

<style lang="scss" >
@import "vue-select/dist/vue-select.css";

.vs__selected-options input[class] {
    padding: 0.55rem;
}

.vs__selected {
    padding: 0.55rem;
}

.vs__deselect {
    margin: 6px;
    margin-left: 8px;
}
</style>