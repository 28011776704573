//
import axios from 'axios'

export const products = {
    namespaced: true,
    state: {
        products: []
     },
    getters: {
        allProducts: (state) => {
            //
            return state.products
        },
        getProduct: (state) => {
            //
            const data = state.products
        
            return data
        },
        getPrefProduct: (state) => (pref)  => {
            //
            const data = state.products.filter(p=> pref.includes(p.category))
        
            return data
        },
        searchProduct: state => (search) => {
            //
            const results = state.products.filter(({ product, shop, category, tags }) => product.toLowerCase().includes(search.toLowerCase()) || shop.toLowerCase().includes(search.toLowerCase()) || category.toLowerCase().includes(search.toLowerCase()) || tags.includes(search.toLowerCase()))

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchPrefProduct: state => (pref, search) => {
            const prefPro = state.products.filter(p=> pref.includes(p.category))

            let results = prefPro.filter(({ product, shop, category, tags, description }) => product.toLowerCase().includes(search.toLowerCase()) || shop.toLowerCase().includes(search.toLowerCase()) || category.toLowerCase().includes(search.toLowerCase()) || tags.includes(search.toLowerCase()) || description.toLowerCase().includes(search.toLowerCase()))

            return results
        },
        searchMainProduct: state => (search) => {
            
            const results = state.products.filter(({ product, business, category, tags }) => product.toLowerCase().includes(search.toLowerCase()) || business.toLowerCase().includes(search.toLowerCase()) || category.toLowerCase().includes(search.toLowerCase()) || tags.includes(search.toLowerCase()) )

            const allBusiness =  results.map(product => product.business)
            const businesses  = [...new Set(allBusiness)]

            return businesses
        }, 
        searchBusinessProduct: state => (search) => {
            //
            const results = state.products.filter(({ product }) => product.toLowerCase().includes(search.toLowerCase()) )

            return results
        }
        // currentBusiness: state => (name) => {
        //     // if (business) { return }
        
        //     const results = state.vendors.find(({ business }) => business.includes(name))
        
        //     return results
        // }
    },
    actions: {
        async getProducts({ state,commit },  shop) {
            //
            let data
            //
            if (shop.length > 0) {
                axios.get('/products/'+shop).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                    for (let index = 0; index < data.length; index++) {
            
                        // to 2 decimalplaces
                        data[index].qty = 0
                    }
                    // })()
                    commit('updateProducts', data)
                })

            } else {
                data = []
            }


            
        }
    },
    mutations: {
        updateProducts: (state, data) => {
            state.products = data
        }
    }
}