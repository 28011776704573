
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :  false;
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        getUser(state) {
            return  state.user
        }
    },
    actions: {
        signin({ commit, state }, user){
            //
            commit('signin', user)
        },
        updateUser({ commit }) {
            // get latest update in local storage
            commit('updateUser')
        }
    },
    mutations: {
        signin:  (state, user) => {
            state.status = { loggedIn: true }
            state.user = user
        },
        updateUser:  (state) => {
            if (state.status.loggedIn) {
                //
                const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :  false;
                state.user = user
            }
        }
    }
}