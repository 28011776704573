<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <!-- <feather @click="goBback" type="arrow-left" size="22" class="p-2 m-5 close"></feather> -->
        <feather @click="goBack" type="arrow-left" size="22" class="p-2 m-5 close"></feather>
        <!-- <feather @click="goHome" type="home" size="22" class="p-2 m-5 home "></feather> -->
        <b-button @click="addToCart" v-if="currentShop.mode == 'Online'" class="home mt-5 mr-5" type="is-dark" rounded> {{ (alreadyInCart ? (currentProduct.qty <= 0 ? 'Remove' : 'Update Cart') : 'Add to Cart') }} {{ currentProduct.qty <= 0 ? '' : '- '+currentProduct.currency+formatPrice(currentProduct.totalPrice)}}</b-button>
        <feather v-else @click="goShop" type="shopping-cart" size="22" class="p-2 m-5 home "></feather>
        <div class="p-2" v-if="currentProduct.shop">
            <!-- `${require('@/assets/img/'+currentProduct.image+'')}` -->
               <b-image
                    :src="currentProduct.image" :alt="currentProduct.image"
                    webp-fallback=".jpg"
                    :ratio="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? '4by3' : '5by3'))"
                ></b-image>
                <div class="content p-1 mb-1">
                    <div class=" pb-1">
                        
                    </div>
                    <!-- <hr> -->
                    <div class="p-5 card ">
                        
                        <h3 class="title is-3">{{ currentProduct.product }} 
                            
                            <b-tag :type="`${ (currentProduct.offerType == 'Discount' ? 'is-primary' : currentProduct.offerType == 'Coupon' ? 'is-warning text-dark' : currentProduct.offerType == 'Gift with Purchase' ? 'is-info' : currentProduct.offerType == 'Promo' ? 'is-danger' : 'is-success') }`" class="mb-1"> {{ currentProduct.offerType }}</b-tag>
                        
                        </h3>
                        <p class="subtitle has-text-grey ml-1 mb-2"> {{ currentProduct.shop }} </p>
                        <p v-if="currentProduct.discount !== 0" class="mx-1 mb-2 is-4 has-text-weight-medium" >
                            <span class="has-text-primary  has-text-weight-bold" > {{ currentProduct.currency }}{{ formatPrice( getDiscount(currentProduct.price, currentProduct.discount) ) }} </span>
                            <span class="mr-1 small has-text-grey" style="text-decoration:line-through"> {{ currentProduct.currency }}{{ formatPrice(currentProduct.price) }} </span> 

                            <b-tag type="is-danger"> Save {{ currentProduct.currency }} {{ formatPrice(currentProduct.price - getDiscount(currentProduct.price, currentProduct.discount).toFixed(2) ) }} </b-tag>
                        </p>
                        <p v-else class="mx-1 is-4  mb-2 has-text-weight-medium">{{ currentProduct.currency }}{{ formatPrice(currentProduct.price) }}</p>
                        <b-tag v-if="currentProduct.limited" type="is-danger"> Limited </b-tag>
                        <p class="mx-1 mb-2 is-3 has-text-grey has-text-weight-medium"> {{ currentProduct.type }} </p>
                        <!-- <hr> -->
                        <div class="py-2"></div>
                        <p class="mx-1 mb-2 is-3 has-text-grey"><b-tag type="is-primary" class="mr-2"> {{ currentProduct.category }} </b-tag>  </p>
                        <div class="mx-2 mb-2 has-text-grey">
                            {{ currentProduct.details }}
                        </div>
                        <!-- <p class="mx-1 mb-2 is-3 has-text-grey">  </p> -->
                        
                        <p class="has-text-grey small is-flex">
                             
                            <span v-if="currentProduct.expiry" class="has-text-dark has-text-weight-medium is-flex is-align-items-center ml-2">  <feather size="16" class="has-text-danger mr-1" type="clock"></feather>  {{ (new Date(currentProduct.start)).toDateString().substring(4,15)+" — "+(new Date(currentProduct.end)).toDateString().substring(4,15) }} </span>
                        </p>


                        <div style="width: 50%;margin:auto">
                            <div v-if="false" class="columns is-mobile text-center" style="margin:auto">
                                <div class="column">
                                    <b-button :disabled="alreadyInCart ? currentProduct.qty <= 0 : currentProduct.qty == 1" @click="lessQty" rounded icon-left="minus"></b-button>
                                </div>
                                <div class="column py-4 m-1">
                                    <p>{{ currentProduct.qty }}</p>
                                </div>
                                <div class="column">
                                    <b-button @click="addQty" rounded icon-left="plus"></b-button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class=" cart-cover" :style="(screenWidth <= 560 ? '' : (screenWidth <= 986 ? '' : 'width: 890px; margin: auto;') )"> -->
                        <!-- <div class="pt-2 px-0 cart-cover"> -->
                            <!-- <b-button @click="addToCart" :type="(alreadyInCart) ? 'is-dark' : 'is-primary'" class="p-3 has-text-weight-medium addtocart" size="is-medium" rounded expanded> {{ (alreadyInCart ? 'Update Cart' : 'Add to Cart') }} - {{ currency+formatPrice(currentProduct.totalPrice)}}</b-button> -->
                        <!-- </div> -->
                    </div>
                    <div v-if="currentProduct.tags.length > 0" class="p-5 card mt-3">
                        <div class="p-1">
                            <h3 class="title is-6 mb-2 mx-2">Tags </h3>

                            <div class="py-3 px-2">
                                
                                <b-button type="is-primary" outlined rounded class="mr-2 mb-3" v-for="(tags, index) in currentProduct.tags" :key="index" size="is-small">{{ tags }}</b-button>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentProduct.description.length > 0" class="p-5 card mt-3">
                        <div class="p-1">
                            <h3 class="title is-6 mb-2 mx-2 mb-4">Description </h3> 
                            <div style="white-space: pre-wrap;" class="mx-1">{{ currentProduct.description }} </div>
                        </div>
                    </div>
                    <div class="py-4">

                    </div>

                    <div class=" cart-cover" :style="(screenWidth <= 560 ? '' : (screenWidth <= 986 ? '' : 'width: 890px; margin: auto;') )">
                        <!-- <div class="pt-2 px-0 cart-cover"> -->
                       <div class="columns is-mobile">
                        <div v-if="currentShop.whatsapp" class="column">
                            <b-button tag="a" 
                                target="_blank"
                                :href="'https://wa.me/'+(currentShop.whatsapp.countryCode+currentShop.whatsapp.tel)+'?text=Hello '+encodeURIComponent(`${currentShop.name}, from Shopnscan.app, \n\n_*Enquiry*:_ \nProduct: ${currentProduct.product} \nCategory: ${currentProduct.category} \nPrice: ${currentProduct.discount !== 0 ? currentProduct.currency+formatPrice(getDiscount(currentProduct.price, currentProduct.discount)) : currentProduct.currency+formatPrice(currentProduct.price) } ${currentProduct.discount !== 0 ? `~${currentProduct.currency+currentProduct.price}~ @${currentProduct.discount}% off` : ``} \nDetails: ${currentProduct.details} \nType: ${currentProduct.type} \nDescription: ${currentProduct.description} \n \n_from ${$store.getters['auth/getUser'].firstname}_ \n Thank you!`)" 
                                type="is-primary" 
                                class="p-3  has-text-weight-medium is-5 title" 
                                size="is-medium" 
                                rounded 
                                expanded> Whatsapp</b-button>
                        </div>
                        <div class="column">
                            <b-button tag="a" :href="`tel:${currentShop.phone.countryCode+currentShop.phone.tel}`" type="is-dark" class="p-3  has-text-weight-medium is-5 title" size="is-medium" rounded expanded> Call </b-button>
                        </div>
                       </div> 
                        
                    </div>

                    <div v-if="false" class=" cart-cover" :style="(screenWidth <= 560 ? '' : (screenWidth <= 986 ? '' : 'width: 890px; margin: auto;') )">
                        <!-- <div class="pt-2 px-0 cart-cover"> -->
                        
                        <b-button @click="addToCart" :type="(alreadyInCart) ? (currentProduct.qty <= 0 ? 'is-danger' : 'is-dark') : 'is-primary'" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded>
                            
                        {{ (alreadyInCart ? (currentProduct.qty <= 0 ? 'Remove' : 'Update Cart') : 'Add to Cart') }} {{ currentProduct.qty <= 0 ? '' : '- '+currentProduct.currency+formatPrice(currentProduct.totalPrice)}}
                            
                        </b-button>
                    </div>
                    <div class="py-3">

                    </div>
                </div>
                
                 
                
                
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import axios from 'axios'

    export default {
        name: "ProductPage",
        data() {
            return {
                screenWidth: 0,
                currentProduct: {
                    id: '',
                    available: '',
                    shop: '', 
                    image: '',
                    product: '', 
                    discount: '',
                    price: '',
                    type: '',
                    details: '',
                    description: '',
                    totalPrice: 0,
                    qty: 1,
                    currency: '',
                    start: '',
                    end: '',
                    expiry: '',
                    limited: '',
                    offerType: '',
                },
                currentShop: {
                    phone: {
                        countryCode: '',
                        tel: ''
                    },
                },
                cartSubmitted: false
            }
        },
        computed: {
            // ...mapGetters('products', ['allProducts']),
            ...mapGetters('cart', ['getCartShop']),
            
            alreadyInCart() {
                // console.log(this.$store)
                return this.$store.getters['cart/alreadyInCart'](this.currentProduct.id)
            },
            inCart() {
                // console.log(this.$store)
                const cart = this.$store.getters['cart/inCart'](this.currentProduct.id)
                return cart
            },
            // alreadyAddedtoWishlist() {
            //     return this.$store.getters.cart.alreadyInWishlist(this.id)
            // } 
        },
        mounted(){
            // console.log(this.$route)
            //
            // this.currentProduct = this.$route.params.product

            //
            if (Object.keys(this.$route.params).length !== 0) {
                
                const data = {
                        link: 'product',
                        data: this.$route.params
                    }

                this.currentProduct.id = this.$route.params.id
                this.currentProduct.available = this.$route.params.available
                this.currentProduct.shop = this.$route.params.shop
                this.currentProduct.image = this.$route.params.image
                this.currentProduct.product = this.$route.params.product
                this.currentProduct.category = this.$route.params.category
                this.currentProduct.discount = this.$route.params.discount
                this.currentProduct.price = this.$route.params.price
                this.currentProduct.tags = this.$route.params.tags
                this.currentProduct.totalPrice = (this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.price : this.currentProduct.price)
                this.currentProduct.type = this.$route.params.type
                this.currentProduct.details = this.$route.params.details
                this.currentProduct.description = this.$route.params.description
                this.currentProduct.currency = this.$route.params.currency
                this.currentProduct.start = this.$route.params.start
                this.currentProduct.end = this.$route.params.end
                this.currentProduct.expiry = this.$route.params.expiry
                this.currentProduct.limited = this.$route.params.limited
                this.currentProduct.offerType = this.$route.params.offerType

                this.$store.dispatch('pages/setPage', data)
            }
            else {
                const data = this.$store.getters['pages/getPage']('product')

                if (data !== null) {
                        // this.form = data.search
                        this.currentProduct.id = data.id
                        this.currentProduct.available = data.available
                        this.currentProduct.shop = data.shop
                        this.currentProduct.image = data.image
                        this.currentProduct.product = data.product
                        this.currentProduct.category = data.category
                        this.currentProduct.discount = data.discount
                        this.currentProduct.price = data.price
                        this.currentProduct.tags = data.tags
                        this.currentProduct.totalPrice = (this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.price : this.currentProduct.price)
                        this.currentProduct.type = data.type
                        this.currentProduct.details = data.details
                        this.currentProduct.description = data.description
                        this.currentProduct.currency = data.currency
                        this.currentProduct.start = data.start
                        this.currentProduct.end = data.end
                        this.currentProduct.expiry = data.expiry
                        this.currentProduct.limited = data.limited
                        this.currentProduct.offerType = data.offerType
                }
            }
            

            //
            if (this.alreadyInCart) {
                this.currentProduct.qty = parseInt(this.inCart.unit)
                this.currentProduct.totalPrice = parseInt(this.inCart.total)
            }

            this.updateScreenWidth();
            this.onScreenResize();
            this.getShop()
        },
        methods: {
            getShop() {
                //
                axios.get('/shop?shop='+this.currentProduct.shop).then((res)=>{
                    //
                    this.currentShop = res.data[0];

                })
            },
            lessQty() {
                // if (this.qty <= 1) {
                this.currentProduct.qty--
                this.currentProduct.totalPrice = this.currentProduct.qty * (this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.price : this.currentProduct.price)
                // }
            },
            addQty() {
                this.currentProduct.qty++
                this.currentProduct.totalPrice = this.currentProduct.qty * ( this.currentProduct.discount > 0 ? ((100 - this.currentProduct.discount)/100) * this.currentProduct.price : this.currentProduct.price)
                
            },
            formatPrice(price) {
                return parseFloat(price).toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            loadProduct(product){
                this.currentProduct = product
                //open
                this.isCardModalActive = true
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goShop() {
                this.$router.push({ name:'shop', params: this.currentShop })
            },
            goHome() {
                //
                this.$router.push('/')
            },
            goBack() {
                this.$router.back()
            },
            getImage(img) {
                //
                const link = `http://localhost:4000/api/images/product/${this.$store.getters['auth/getUser'].token}/*/${img.split('/')[1]}`
                // 'http://localhost:4000\\api\\streams\\'+token+'\\'+cpath.split("\\").join('*')+'\\'+ev.name
                return link
            },
            addToCart() {

                // if (this.alreadyAddedtoCart) {
                //     return
                // }

                if (this.getCartShop !== null) {

                    if (this.getCartShop !== this.currentProduct.shop){
                        
                    // })
                        this.$buefy.dialog.confirm({
                            title: 'Starting a new Order?',
                            message: `This will clear your <b>${this.getCartShop} </b> Order.`,
                            confirmText: 'Confirm',
                            type: 'is-danger',
                            // hasIcon: true,
                            onConfirm: () => {
                                // empt cart
                                this.$store.dispatch('cart/emptyCart')
                                //

                                let formOutput = {
                                    available: this.currentProduct.available,
                                    id: this.currentProduct.id,
                                    discount: this.currentProduct.discount,
                                    shop: this.currentProduct.shop,
                                    product: this.currentProduct.product,
                                    image: this.currentProduct.image,
                                    tags: this.currentProduct.tags,
                                    unit: this.currentProduct.qty,
                                    offerType: this.currentProduct.offerType,
                                    price: this.currentProduct.price
                                };
                                
                                this.$store.dispatch('cart/addToCart', formOutput)

                            }
                        })
                    
                        return
                    }
                }

                let formOutput = {
                    available: this.currentProduct.available,
                    id: this.currentProduct.id,
                    discount: this.currentProduct.discount,
                    shop: this.currentProduct.shop,
                    product: this.currentProduct.product,
                    tags: this.currentProduct.tags,
                    image: this.currentProduct.image,
                    unit: this.currentProduct.qty,
                    offerType: this.currentProduct.offerType,
                    price: this.currentProduct.price
                };
                

                this.cartSubmitted = true;
                this.$store.dispatch('cart/addToCart', formOutput)

                if (this.currentProduct.qty <= 0) {
                    this.addQty()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .addtocart {
        padding: 1.7rem !important;
    }

    .cart-cover {
        position: fixed;
        width: calc(100% - 28px);
        // left: 0;
        // padding-bottom: 20px;
        // margin-bottom: 10px;
        bottom: 10px;
    }
</style>